// Component to render a OperatorTruckIndexRecord result
import {
  OperatorHitProps,
  OperatorTruckIndexRecord,
} from '@isheepdog/operator-search';
import { useMediaQuery } from '@uidotdev/usehooks';
import clsx from 'clsx';
import Image from 'next/image';

import { compact, startCase } from 'lodash';

import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';

import { getJobInitialSiteRefMap } from '@/lib/firebase/db/helpers';
import { getIsFauxOperatorTruck } from '@/lib/firebase/db/helpers/operatorTruckHelpers';

import { DEFAULT_AVATAR_URL } from '@/lib/env';
import { UserRole, isAssigner } from '@/lib/helpers/userRoles';

export default function OperatorSearchResult(props: OperatorHitProps) {
  const { selectedJob } = useEditSchedulerContext();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)');
  const operatorTruck = props.hit;

  const distanceToSite = operatorTruck._rankingInfo?.milesToCenter;

  if (!selectedJob) return;

  const initialSiteName = getJobInitialSiteRefMap(selectedJob)?.name;

  const matchingResults = operatorTruck._matchingResults;

  return (
    <div
      className={clsx(
        'btn btn-ghost btn-block mt-1 h-auto flex-nowrap justify-start rounded-lg border-2 border-base-200 p-2',
        props.isHighlighted && 'btn-active'
      )}
      onClick={() => props.onClick(operatorTruck)}
    >
      {!isSmallDevice && (
        <div className="size-12">
          <OperatorAvatar operatorTruck={operatorTruck} />
        </div>
      )}
      <div className="max-w-72 text-left">
        <div>
          <span
            className={clsx(
              'operator-truck-nickname',
              getIsFauxOperatorTruck(operatorTruck) && 'italic'
            )}
          >
            {operatorTruck.operator.nickname}
          </span>
          <OperatorRoleTag operatorTruck={operatorTruck} />
        </div>
        <div className="operator-truck-user-name line-clamp-1 text-sm">
          {getIsFauxOperatorTruck(operatorTruck)
            ? `${operatorTruck.operator.email}`
            : `${operatorTruck.operator.firstName} ${operatorTruck.operator.lastName}`}
        </div>
        <div className="operator-truck-company-name text-xs">
          {operatorTruck.company.name}
          {isAssigner(operatorTruck) &&
            compact([
              ` (`,
              !!matchingResults && `${matchingResults} of `,
              `${operatorTruck.company.totalTrucks} Truck`,
              operatorTruck.company.totalTrucks > 1 && 's',
              !!matchingResults && ` matching filters`,
              `)`,
            ]).join('')}
        </div>
        {!!distanceToSite && (
          <div className="text-[0.6rem]">{`${distanceToSite.toFixed(0)} miles away from ${initialSiteName}`}</div>
        )}
      </div>
    </div>
  );
}

function OperatorAvatar({
  operatorTruck,
}: {
  operatorTruck: OperatorTruckIndexRecord;
}) {
  return (
    <div className="avatar">
      <div className="w-12 rounded-full">
        <Image
          src={operatorTruck.operator.avatar || DEFAULT_AVATAR_URL}
          alt="user-avatar"
          width={48}
          height={48}
          placeholder="blur"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAXCAIAAACAiijJAAAACXBIWXMAABBNAAAQTQFnjAHgAAABU0lEQVQ4jd1STXOCMBDNxg1hCFqsYw/Qk/3/v6f+AESpCh6IjhCyPdBRyuBHxx46zSEz2ex7+3beg/n8nT12+IP4/0SB7Yfv+0EQHI+lEGK9/qiqqv0rhJhOX4ypHMfZ7XZFUXRVAEAYRp6nXFdaWxtjOtOMMdbWUkrPU2EYAUCXgoiIKEkSIpamKRGdpSJyzokoTVMiliRJ09yzSJ5nWheHw34wGHie0rqo67qZL6WU0t3v9XKZWGvzPDvL743WePw8mUyIaLVaaq1d142iVwDYbrdt8DWKryU5JyJENMYAgLW2v+0SnjE2HA4RcTZ7Q8TRaHRxUm8VEZ+eAsZY42tz+77vOM69FAAny7r1eyl+dP4yBREJIQC+xV9K2Y7sDQpjzGazUUohImMMEZVSWZaVZXkvRSNksYgbCzjncRz3SmDX03la4RL4hoq2nOsNv+DIJy5LqYGaHBn/AAAAAElFTkSuQmCC"
        />
      </div>
    </div>
  );
}

function OperatorRoleTag({
  operatorTruck,
}: {
  operatorTruck: OperatorTruckIndexRecord;
}) {
  const roleLabel: Partial<Record<UserRole, string>> = {
    operatorEmployee: 'Employee',
    operatorIndependent: 'Independent',
    operatorOwner: 'Owner',
  };
  const role = operatorTruck.operator.role as UserRole;

  return (
    <div className="badge badge-sm ml-1 bg-base-200 text-[0.66rem]">
      {roleLabel[role] || startCase(role)}
    </div>
  );
}
