// Fetch and list the jobs to be displayed in Scheduler Dashboard
import { useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import Image from 'next/image';

import { flow, noop, orderBy, times, unionBy } from 'lodash';

import isdLogo from '@public/isd-logo-primary-light.svg';

import EditDispatchScheduleActionButtons from '@/components/Job/JobsList/EditDispatchScheduleActionButtons';
import TotalTrucksCount from '@/components/Job/JobsList/TotalTrucksCount';
import JobCard from '@/components/Scheduler/SchedulerMain/JobCard';

import { useAuthContext } from '@/lib/context/AuthContext';
import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';
import {
  jobsSorters,
  useSchedulerContext,
} from '@/lib/context/SchedulerContext';

import { firestore } from '@/lib/firebase';
import { filterJobsByLocation } from '@/lib/firebase/db/helpers';
import { JobDoc, UserDoc } from '@/lib/firebase/db/metaTypes';
import {
  JOBS_BY_DATE_DEFAULT_SORT_FIELD,
  JOBS_BY_DATE_DEFAULT_SORT_ORDER,
  jobsByDispatchScheduleIdQuery,
  jobsForDispatchScheduleQuery,
} from '@/lib/firebase/db/queries';

import { reactAppJobsListRoute, reactAppUrl } from '@/lib/env';
import { milesToMeters } from '@/lib/helpers/maps';
import { getIsJobFiltered } from '@/lib/helpers/scheduler';

export default function JobsList() {
  const [jobs, setJobs] = useState<JobDoc[]>([]);
  const [parametrizedJobs, setParametrizedJobs] = useState<JobDoc[] | null>(
    null
  );
  const [manuallyAssignedJobs, setManuallyAssignedJobs] = useState<
    JobDoc[] | null
  >(null);

  const [isLoading, setIsLoading] = useState(true);
  const { userDoc } = useAuthContext();
  const {
    selectedDispatchSchedule,
    schedulerCurrentDate,
    schedulerSortField,
    schedulerSortOrder,
    initializeSchedulerFilterOptions,
    activeFilters,
    isLoadingDispatchScheduleOptions,
    brokerCompanies,
  } = useSchedulerContext();
  const { isLoading: isEditSchedulerLoading } = useEditSchedulerContext();

  const isUserLoaded = useMemo(() => !!userDoc?.id, [userDoc]);

  // Use a flag to check if should run a query to get All Jobs or
  // Jobs based on selected schedule parameters. If schedule has no
  // parameter set then it should only load manually assigned jobs.
  const shouldLoadParametrizedJobs = useMemo(() => {
    if (!isUserLoaded || isLoadingDispatchScheduleOptions) {
      return false;
    }
    // If there's no schedule selected then load all jobs for current date
    if (!selectedDispatchSchedule.dispatchScheduleDoc) {
      return true;
    }
    // Check if selected schedule has some param to filter jobs
    const { dispatchScheduleDoc } = selectedDispatchSchedule;
    return !!(
      dispatchScheduleDoc.get('clientsIds')?.length ||
      dispatchScheduleDoc.get('radiusZipCode') ||
      dispatchScheduleDoc.get('sitesIds')?.length
    );
  }, [
    isUserLoaded,
    isLoadingDispatchScheduleOptions,
    selectedDispatchSchedule,
  ]);

  useEffect(() => {
    setIsLoading(true);
    setJobs([]);
    // Clear or reset parametrized jobs state
    if (
      !shouldLoadParametrizedJobs &&
      isUserLoaded &&
      !isLoadingDispatchScheduleOptions
    ) {
      setParametrizedJobs([]);
    } else {
      setParametrizedJobs(null);
    }
    const unSubscribeFromParametrizedJobs = !shouldLoadParametrizedJobs
      ? noop
      : firestore.onSnapshot(
          jobsForDispatchScheduleQuery({
            userDoc: userDoc as UserDoc,
            date: schedulerCurrentDate,
            dispatchScheduleDoc: selectedDispatchSchedule.dispatchScheduleDoc,
          }),
          (querySnapshot) => {
            let docs = firestore.filterSoftDeletedDocs(
              querySnapshot.docs
            ) as JobDoc[];
            // Check if should filter jobs by distance to center point
            const { radiusGeoPoint, radiusDistanceMiles } =
              selectedDispatchSchedule.dispatchScheduleDoc?.data() || {};
            if (!!radiusGeoPoint) {
              docs = filterJobsByLocation(
                docs,
                radiusGeoPoint,
                milesToMeters(+(radiusDistanceMiles || 0))
              );
            }
            // Check if should filter jobs by sites
            const { sitesIds } =
              selectedDispatchSchedule.dispatchScheduleDoc?.data() || {};
            if (!!sitesIds?.length) {
              docs = docs.filter((jobDoc) =>
                jobDoc
                  .get('sitesIds')
                  ?.some((jobSiteId) => sitesIds.includes(jobSiteId))
              );
            }
            setParametrizedJobs(docs);
          }
        );

    const dispatchScheduleId = selectedDispatchSchedule.dispatchScheduleId;
    // NOTE: Manually Assigned Jobs feature (DSP-89) is currently on hold as of 10/21/2024.
    const shouldLoadManuallyAssignedJobs = false; // isUserLoaded && !isLoadingDispatchScheduleOptions && !!dispatchScheduleId;
    // Clear or reset manually assigned jobs state
    if (!shouldLoadManuallyAssignedJobs) {
      setManuallyAssignedJobs([]);
    } else {
      setManuallyAssignedJobs(null);
    }

    const unSubscribeFromManuallyAssignedJobs =
      !shouldLoadManuallyAssignedJobs || !dispatchScheduleId
        ? noop
        : firestore.onSnapshot(
            jobsByDispatchScheduleIdQuery({
              date: schedulerCurrentDate,
              dispatchScheduleId,
            }),
            (querySnapshot) => {
              let docs = firestore.filterSoftDeletedDocs(
                querySnapshot.docs
              ) as JobDoc[];
              setManuallyAssignedJobs(docs);
            }
          );

    return flow([
      unSubscribeFromParametrizedJobs,
      unSubscribeFromManuallyAssignedJobs,
    ]);
  }, [
    isUserLoaded,
    shouldLoadParametrizedJobs,
    selectedDispatchSchedule,
    schedulerCurrentDate,
    schedulerSortField,
    schedulerSortOrder,
    isLoadingDispatchScheduleOptions,
  ]);

  useEffect(() => {
    // Merge parametrized and manually selected jobs
    let jobs = unionBy(parametrizedJobs, manuallyAssignedJobs, 'id');

    // Check if should re-order the query result
    if (
      schedulerSortField !== JOBS_BY_DATE_DEFAULT_SORT_FIELD ||
      schedulerSortOrder !== JOBS_BY_DATE_DEFAULT_SORT_ORDER
    ) {
      const jobSorterFn = jobsSorters[schedulerSortField];
      if (!!jobSorterFn) {
        jobs = jobSorterFn(jobs, schedulerSortOrder);
      } else {
        jobs = orderBy(
          jobs,
          (jobDoc) => jobDoc.get(schedulerSortField),
          schedulerSortOrder
        );
      }
    }

    setJobs(jobs);
    initializeSchedulerFilterOptions(jobs);
    // Keep loading flag active until both job queries have been initialized
    setIsLoading(!manuallyAssignedJobs || !parametrizedJobs);
  }, [parametrizedJobs, manuallyAssignedJobs]);

  const jobsFiltered = useMemo(() => {
    return jobs.filter((job) =>
      getIsJobFiltered(job, activeFilters, { brokerCompanies })
    );
  }, [jobs, activeFilters, brokerCompanies]);

  return isLoading || !!jobsFiltered.length ? (
    <JobsListContent
      jobs={jobsFiltered}
      isLoading={isLoading || isEditSchedulerLoading}
    />
  ) : (
    <EmptyJobsListContent selectedDate={schedulerCurrentDate} />
  );
}

function JobsListContent({
  jobs,
  isLoading,
}: {
  jobs: JobDoc[];
  isLoading: boolean;
}) {
  const {
    canUserEditDispatchSchedule,
    selectedGroup,
    selectedJob,
    operationsRecordList,
  } = useEditSchedulerContext();
  return (
    <div
      className={clsx(
        'jobs-list min-h-full w-full items-start rounded bg-base-150 px-3 pb-10 pt-5',
        // Use grid layout only when there are enough jobs to cover the entire container
        (isLoading || jobs.length > 8) && 'grid',
        selectedGroup === null && 'md:px-10 2xl:px-12'
      )}
    >
      <div className="flex pb-5">
        <div className="w-full">
          {canUserEditDispatchSchedule &&
            (!selectedJob && !operationsRecordList.length ? (
              <div className="text-xl">{'Click to Select'}</div>
            ) : (
              <EditDispatchScheduleActionButtons jobs={jobs} />
            ))}
        </div>
        <TotalTrucksCount jobs={jobs} isLoading={isLoading} />
      </div>
      <div
        className={clsx(
          'grid h-full w-full gap-y-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4',
          selectedGroup !== null ? 'gap-x-3' : 'gap-x-4 2xl:gap-x-8'
        )}
      >
        {isLoading
          ? times(12, (idx) => (
              <div
                key={idx}
                className="skeleton h-full min-h-36 w-full bg-base-200"
              ></div>
            ))
          : jobs.map((job) => <JobCard key={job.id} jobDoc={job} />)}
      </div>
    </div>
  );
}

function EmptyJobsListContent({ selectedDate }: { selectedDate: Date }) {
  const onClickAddJob = () => {
    // Redirect to react-app's Jobs List page with currently selected date as filter
    const dateStr = selectedDate.getDateISOStr();
    const urlParams = new URLSearchParams({
      selectedJobViewName: 'By Date',
      startDate: dateStr,
      endDate: dateStr,
    });
    const addJobUrl = `${reactAppUrl}${reactAppJobsListRoute}?${urlParams}`;
    window.open(addJobUrl);
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="prose flex flex-col items-center self-center">
        <Image src={isdLogo} width={400} alt={'isd-logo'} />
        <h4 className="mt-0">
          {'First, '}
          <span
            onClick={onClickAddJob}
            className="clickable tooltip text-primary hover:underline"
            data-tip={`Use "Add To Dispatch Schedule" option in any job to assign them to this Schedule`}
          >
            {'add a job'}
          </span>
          {' to schedule your dispatches.'}
        </h4>
      </div>
    </div>
  );
}
