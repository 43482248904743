// Component to show a Dispatch Schedule Form inside a modal
import DispatchScheduleForm from '@/components/DispatchSchedule/DispatchScheduleForm';
import Modal from '@/components/Modal/Modal';

import { DispatchScheduleDoc } from '@/lib/firebase/db/metaTypes';

export type CloseDispatchScheduleFormModalFn = (
  ds: DispatchScheduleDoc | void
) => void;

export default function DispatchScheduleFormModal({
  isOpen,
  onClose,
  dispatchScheduleDoc,
}: {
  isOpen: boolean;
  onClose: CloseDispatchScheduleFormModalFn;
  dispatchScheduleDoc: DispatchScheduleDoc | null;
}) {
  return (
    <Modal isOpen={isOpen}>
      {isOpen && (
        <DispatchScheduleForm
          onClose={onClose}
          dispatchScheduleDoc={dispatchScheduleDoc}
        />
      )}
    </Modal>
  );
}
