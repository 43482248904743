// Component to display group(s) of Operators for a Job as a Card footer
import { useEffect, useState } from 'react';

import { PlusIcon } from '@heroicons/react/16/solid';
import clsx from 'clsx';
import { useDrop } from 'react-dnd';

import { compact, groupBy, map, noop, orderBy, times } from 'lodash';

import JobAssignmentInfo from '@/components/Scheduler/SchedulerMain/JobCard/JobGroups/JobAssignmentInfo';

import { useAuthContext } from '@/lib/context/AuthContext';
import {
  parseJobAssignmentsBasedOnOperationsRecord,
  useEditSchedulerContext,
} from '@/lib/context/EditSchedulerContext';

import { firestore } from '@/lib/firebase';
import { getJobStartDatetime } from '@/lib/firebase/db/helpers';
import { JobAssignmentDoc, JobDoc } from '@/lib/firebase/db/metaTypes';
import { jobsAssignmentsLeafNodesByJobQuery } from '@/lib/firebase/db/queries';

import { reactAppJobEditRoute, reactAppUrl } from '@/lib/env';
import { replaceParamsInRoute } from '@/lib/helpers/functions';

export default function JobGroups({ jobDoc }: { jobDoc: JobDoc }) {
  const [originalJobAssignments, setOriginalJobAssignments] = useState<
    JobAssignmentDoc[] | null
  >(null);
  const [jobAssignments, setJobAssignments] = useState<JobAssignmentDoc[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userDoc } = useAuthContext();
  const { selectedJob, onChangeSelectedJobAssignments, operationsRecordList } =
    useEditSchedulerContext();

  useEffect(() => {
    const unSubscribe = !userDoc
      ? noop
      : firestore.onSnapshot(
          jobsAssignmentsLeafNodesByJobQuery({ userDoc, jobId: jobDoc.id }),
          (querySnapshot) => {
            setOriginalJobAssignments(
              firestore.filterSoftDeletedDocs(querySnapshot.docs)
            );
          }
        );

    return unSubscribe;
  }, []);

  useEffect(() => {
    if (!originalJobAssignments) return;
    const parsedJobAssignments = parseJobAssignmentsBasedOnOperationsRecord(
      jobDoc.id,
      originalJobAssignments,
      operationsRecordList
    ) as JobAssignmentDoc[];
    setJobAssignments(parsedJobAssignments);
    setIsLoading(false);
  }, [operationsRecordList, originalJobAssignments]);

  // Update selected JobAssignments in EditSchedulerContext
  useEffect(() => {
    if (selectedJob?.id === jobDoc.id && !!jobAssignments) {
      onChangeSelectedJobAssignments(jobAssignments);
    }
  }, [jobAssignments?.length, selectedJob]);

  const jobData = jobDoc.data();
  const groupsCount = jobData?.groupsOfOperators.length || 0;
  const jobAssignmentsByGroup = groupBy(
    jobAssignments,
    (jobAssignmentDoc) => jobAssignmentDoc.data()?.groupIndex
  );

  return (
    <div
      className={clsx(
        'job-groups rounded-b-xl p-1 pb-2',
        groupsCount > 1 && 'bg-accent-dark'
      )}
    >
      {isLoading ? (
        <>
          {times(2, (index) => (
            <JobAssignmentLoading key={index} />
          ))}
        </>
      ) : groupsCount > 0 ? (
        map(jobData?.groupsOfOperators, (_, groupIndex) => {
          const jobAssignmentsAtGroup = jobAssignmentsByGroup[groupIndex] || [];
          return (
            <JobAssignmentsGroup
              key={groupIndex}
              jobAssignmentsAtGroup={jobAssignmentsAtGroup}
              groupIndex={groupIndex}
              jobDoc={jobDoc}
              groupsCount={groupsCount}
            />
          );
        })
      ) : (
        <EmptyJobAssignments jobDoc={jobDoc} />
      )}
    </div>
  );
}

function EmptyJobAssignments({ jobDoc }: { jobDoc: JobDoc }) {
  const onClickAddOperators = () => {
    const jobId = jobDoc.id;
    const editJobUrl = `${reactAppUrl}${replaceParamsInRoute(reactAppJobEditRoute, { jobId })}`;
    window.open(editJobUrl);
  };
  return (
    <div
      className="add-operators-link clickable flex  justify-center rounded-2xl bg-base-100 p-3 text-center hover:underline"
      onClick={onClickAddOperators}
    >
      <PlusIcon className="w-5" />
      <div>Add Operators</div>
    </div>
  );
}

function JobAssignmentLoading() {
  return <div className="skeleton mt-1 h-5 w-full bg-base-200"></div>;
}

function getIsSameUserAssignedToThisGroup(
  jobAssignmentToSearch: JobAssignmentDoc,
  jobAssignmentsAtGroup: JobAssignmentDoc[]
) {
  return jobAssignmentsAtGroup.find(
    (jobAssignmentDoc) =>
      jobAssignmentDoc.get('receiver.user.id') ===
      jobAssignmentToSearch.get('receiver.user.id')
  );
}

function JobAssignmentsGroup({
  jobAssignmentsAtGroup,
  groupIndex,
  jobDoc,
  groupsCount,
}: {
  jobAssignmentsAtGroup: JobAssignmentDoc[];
  groupIndex: number;
  jobDoc: JobDoc;
  groupsCount: number;
}) {
  const { onSelectOperatorToMove, selectedGroup } = useEditSchedulerContext();
  // Generate a list of the groups that this group can receive
  const acceptedGroups = compact(
    times(
      groupsCount,
      (group) => group !== +groupIndex && `job-${jobDoc.id}-group-${group}`
    )
  );

  const onDropOperator = ({
    jobAssignmentDoc,
  }: {
    jobAssignmentDoc: JobAssignmentDoc;
  }) => {
    // Check that the same user (possibly an Assigner) is not already assigned to a different group
    if (
      getIsSameUserAssignedToThisGroup(jobAssignmentDoc, jobAssignmentsAtGroup)
    )
      return;
    onSelectOperatorToMove(jobAssignmentDoc, +groupIndex);
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: acceptedGroups,
    drop: onDropOperator,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop:
        monitor.canDrop() &&
        !getIsSameUserAssignedToThisGroup(
          monitor.getItem().jobAssignmentDoc,
          jobAssignmentsAtGroup
        ),
    }),
  });

  const jobAssignmentsSorted: JobAssignmentDoc[] = orderBy(
    jobAssignmentsAtGroup,
    (jobAssignmentDoc) =>
      jobAssignmentDoc.get('receiver.company.name')?.toLowerCase()
  );
  return (
    <>
      {+groupIndex > 0 && (
        <div className="job-start-time line-clamp-1 px-2 py-2 text-right text-base-100">
          {getJobStartDatetime(jobDoc, +groupIndex).getTimeStr()}
        </div>
      )}
      <div
        // @ts-ignore
        ref={drop}
        className={clsx(
          'job-assignments-group relative rounded-xl bg-base-100 pt-0',
          selectedGroup === null ? 'p-2' : 'px-1 pb-2'
        )}
        key={groupIndex}
      >
        {canDrop && <DropPlaceholderOverlay isOver={isOver} />}
        <div className="group mb-0.5 flex h-6 items-center justify-between text-xs font-light italic underline">
          <div>
            Operators:
            <AddOperatorsButton jobDoc={jobDoc} groupIndex={+groupIndex} />
          </div>
          {groupsCount > 1 && <div>{`Group ${+groupIndex + 1}`}</div>}
        </div>
        {!!jobAssignmentsSorted.length ? (
          jobAssignmentsSorted.map((jobAssignmentDoc) => (
            <JobAssignmentInfo
              key={jobAssignmentDoc.id}
              jobAssignmentDoc={jobAssignmentDoc}
              jobDoc={jobDoc}
            />
          ))
        ) : (
          <div className="w-full py-1 text-center text-xs">
            {'No Operators have been assigned to this Group'}
          </div>
        )}
      </div>
    </>
  );
}

function AddOperatorsButton({
  jobDoc,
  groupIndex,
}: {
  jobDoc: JobDoc;
  groupIndex: number;
}) {
  const {
    canUserEditDispatchSchedule,
    selectedJob,
    onSelectGroup,
    selectedGroup,
    onSelectJob,
  } = useEditSchedulerContext();

  const onClickAddOperator = () => {
    if (!selectedJob) {
      onSelectJob(jobDoc);
    }
    onSelectGroup(isSelectedGroup ? null : groupIndex);
  };

  const isSelectedJob = selectedJob?.id === jobDoc.id;
  const isSelectedGroup = selectedGroup === groupIndex;
  return (
    canUserEditDispatchSchedule && (
      <div
        className={clsx(
          'btn btn-outline btn-secondary btn-xs ml-2 h-4 min-h-4 group-hover:visible',
          isSelectedGroup && 'btn-active',
          !isSelectedJob && 'invisible'
        )}
        onClick={onClickAddOperator}
      >
        Add Operator
      </div>
    )
  );
}

function DropPlaceholderOverlay({ isOver }: { isOver: boolean }) {
  return (
    <div
      className={clsx(
        'absolute left-0 z-20 mr-5 flex h-full w-full items-center justify-center rounded-lg ',
        isOver ? 'bg-cyan-400' : 'bg-info'
      )}
    >
      <div className="font-bold text-base-100">
        {'Move Operator to this group'}
      </div>
    </div>
  );
}
