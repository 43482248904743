// Component to display current Schedule Info
import { useEffect, useState } from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { compact } from 'lodash';

import SchedulerOptionButton from '@/components/Scheduler/SchedulerDays/SchedulerOptions/SchedulerOptionButton';

import { useSchedulerContext } from '@/lib/context/SchedulerContext';

import { firestore } from '@/lib/firebase';
import { Companies, Sites } from '@/lib/firebase/db/collections';

import { getAddressNameByGeoPoint } from '@/lib/helpers/maps';

export default function SchedulerInfo() {
  const { selectedDispatchSchedule } = useSchedulerContext();
  const [clientCompanyName, setClientCompanyName] = useState('');
  const [zipCodeAddress, setZipCodeAddress] = useState('');
  const [milesAround, setMilesAround] = useState('');
  const [sitesNames, setSitesNames] = useState('');

  useEffect(() => {
    async function loadData() {
      const dispatchScheduleData =
        selectedDispatchSchedule.dispatchScheduleDoc?.data();
      if (dispatchScheduleData?.clientsIds?.length) {
        const clientsCompaniesDocs = await Promise.all(
          dispatchScheduleData.clientsIds.map((clientId) =>
            firestore.getDoc(Companies.doc(clientId))
          )
        );
        setClientCompanyName(
          clientsCompaniesDocs
            .map((clientCompanyDoc) => clientCompanyDoc.get('businessName'))
            .join(', ')
        );
      }
      if (dispatchScheduleData?.radiusGeoPoint) {
        const address = await getAddressNameByGeoPoint(
          dispatchScheduleData?.radiusGeoPoint
        );
        setZipCodeAddress(address);
        setMilesAround(dispatchScheduleData.radiusDistanceMiles.toString());
      }
      if (dispatchScheduleData?.sitesIds?.length) {
        const sitesNamesArray = await Promise.all(
          dispatchScheduleData.sitesIds.map((siteId) =>
            firestore
              .getDoc(Sites.doc(siteId))
              .then((siteDoc) => siteDoc.get('name'))
          )
        );
        setSitesNames(sitesNamesArray.join(', '));
      }
    }
    setClientCompanyName('');
    setZipCodeAddress('');
    loadData();
  }, [selectedDispatchSchedule]);

  const scheduleParametersStr = compact([
    !!clientCompanyName && `for ${clientCompanyName}`,
    !!zipCodeAddress && `${milesAround} miles around ${zipCodeAddress}`,
    !!sitesNames && `with these Sites: ${sitesNames}`,
  ]).join(', ');

  return (
    <SchedulerOptionButton
      button={<InformationCircleIcon className="h-6 w-6" />}
      tooltipText="Current Schedule Info"
      tooltipPosition="tooltip-left"
      dropdownContent={
        <div className="w-64 p-5">
          {!selectedDispatchSchedule.dispatchScheduleId ? (
            <div>{'Showing all available Jobs for selected day'}</div>
          ) : (
            <div>
              {[
                `Current schedule displays Jobs `,
                scheduleParametersStr,
                ', and any other Job added to it.',
              ].join('')}
            </div>
          )}
        </div>
      }
    />
  );
}
