// Scheduler Header main component
import BlockSchedulerOptionsOverlay from '@/components/Scheduler/BlockSchedulerOptionsOverlay';
import SchedulerRange from '@/components/Scheduler/SchedulerHeader/SchedulerRange';
import SchedulerSelector from '@/components/Scheduler/SchedulerHeader/SchedulerSelector';

export default function SchedulerHeader() {
  return (
    <div className="scheduler-header relative flex h-full flex-col justify-between px-5 md:flex-row md:items-center">
      {/* Add overlay to prevent switching selected Schedule or week while a Job is selected or changes are not saved*/}
      <BlockSchedulerOptionsOverlay />
      <SchedulerSelector />
      <SchedulerRange />
    </div>
  );
}
