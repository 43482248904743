// Render a panel of buttons to perform actions over EditDispatchScheduleContext
import React from 'react';

import { ArrowUturnLeftIcon } from '@heroicons/react/16/solid';
import { useToggle } from '@uidotdev/usehooks';
import clsx from 'clsx';

import { find } from 'lodash';

import Confirm from '@/components/Modal/Confirm';

import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';

import { JobDoc } from '@/lib/firebase/db/metaTypes';

export default function EditDispatchScheduleActionButtons({
  jobs,
}: {
  jobs: JobDoc[];
}) {
  const {
    onConfirmAssignmentChanges,
    isLoading,
    selectedGroup,
    onRevertAssignmentChanges,
  } = useEditSchedulerContext();
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [isRevertModalOpen, toggleIsRevertModalOpen] = useToggle();

  const onConfirmSave = () => {
    onConfirmAssignmentChanges().then(() => toggleIsModalOpen());
  };

  const onConfirmRevert = () => {
    onRevertAssignmentChanges();
    toggleIsRevertModalOpen();
  };

  return (
    <>
      <div
        className={clsx(
          'action-btn-panel absolute top-[5%] z-10 flex gap-1 rounded-lg bg-base-150 px-3 py-1 shadow-xl',
          selectedGroup === null ? 'left-[5%]' : 'left-[26%]'
        )}
      >
        <ConfirmChangesButton onClick={() => toggleIsModalOpen()} />
        <RevertChangesButton onClick={() => toggleIsRevertModalOpen()} />
      </div>
      <Confirm
        isOpen={isModalOpen}
        isLoading={isLoading}
        content={<ConfirmSaveChangesModalContent jobs={jobs} />}
        onConfirm={onConfirmSave}
        onCancel={toggleIsModalOpen}
      />
      <Confirm
        isOpen={isRevertModalOpen}
        isLoading={isLoading}
        content={<ConfirmRevertChangesContent />}
        confirmBtnLabel="Discard Changes"
        onConfirm={onConfirmRevert}
        onCancel={toggleIsRevertModalOpen}
      />
    </>
  );
}

function ConfirmChangesButton({
  onClick,
}: {
  onClick: React.MouseEventHandler;
}) {
  const { operationsRecordList } = useEditSchedulerContext();
  return (
    <div
      className={clsx(
        'confirm-changes-btn btn btn-primary btn-sm shadow-sm',
        !operationsRecordList.length && 'btn-disabled'
      )}
      onClick={onClick}
    >
      {'Confirm Changes '}
      <span className="text-xl">{'🖫'}</span>
    </div>
  );
}

function RevertChangesButton({
  onClick,
}: {
  onClick: React.MouseEventHandler;
}) {
  const { operationsRecordList } = useEditSchedulerContext();
  return (
    <div
      className={clsx(
        'discard-changes-btn btn btn-sm shadow-sm',
        !operationsRecordList.length && 'btn-disabled'
      )}
      onClick={onClick}
    >
      <div className="tooltip" data-tip="Discard changes">
        <ArrowUturnLeftIcon className="size-5" />
      </div>
    </div>
  );
}

function ConfirmSaveChangesModalContent({ jobs }: { jobs: JobDoc[] }) {
  const { operationsRecordList } = useEditSchedulerContext();
  return (
    <>
      <h3>{'Confirm and Save all the changes made over Job Assignments'}</h3>
      <div>
        <p>{'The following changes are yet to be saved:'}</p>
        <ul className="max-h-96 overflow-y-scroll">
          {operationsRecordList.map((operation, idx) => {
            const { groupIndex, jobAssignmentDoc } = operation;
            if (!jobAssignmentDoc) return;
            const operatorName = `${jobAssignmentDoc.get('receiver.company.name')}, ${jobAssignmentDoc.get(
              'receiver.user.nickname'
            )}`;
            const jobName = find(jobs, ['id', operation.jobId])?.get('name');
            const originalGroup = jobAssignmentDoc.get('groupIndex');
            return (
              <li
                key={`${operation.jobId}-${jobAssignmentDoc.id}`}
                className="text-xs"
              >
                {`"${operatorName}" is being `}
                <span className="font-bold">
                  {operation.type === 'assign'
                    ? 'assigned'
                    : operation.type === 'remove'
                      ? 'removed'
                      : operation.type === 'move' && 'moved'}
                </span>
                {[
                  operation.type === 'assign' && groupIndex !== undefined
                    ? ` to Group ${groupIndex + 1} of`
                    : operation.type === 'remove'
                      ? ' from'
                      : operation.type === 'move' &&
                          groupIndex !== undefined &&
                          originalGroup !== undefined
                        ? ` from Group ${originalGroup + 1} to Group ${groupIndex + 1} at`
                        : '',
                  `job "${jobName}".`,
                ].join(' ')}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

function ConfirmRevertChangesContent() {
  return (
    <div className="text-center">
      <h3>{'Discard all the changes made over Job Assignments'}</h3>
      <div>
        {
          'Are you sure to clear all the unsaved changes? This action cannot be undone.'
        }
      </div>
    </div>
  );
}
