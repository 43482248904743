// Component to display the basic Job info as a card header
import JobBrokerCompanyName from '@/components/Scheduler/SchedulerMain/JobCard/JobInfo/JobBrokerCompanyName';
import JobOperatorsCount from '@/components/Scheduler/SchedulerMain/JobCard/JobInfo/JobOperatorsCount';
import JobStatusBadge from '@/components/Scheduler/SchedulerMain/JobCard/JobInfo/JobStatusBadge';

import { getJobStartDatetime } from '@/lib/firebase/db/helpers';
import { JobDoc } from '@/lib/firebase/db/metaTypes';

import { reactAppJobDetailsRoute, reactAppUrl } from '@/lib/env';
import { replaceParamsInRoute } from '@/lib/helpers/functions';

export default function JobInfo({ jobDoc }: { jobDoc: JobDoc }) {
  const jobData = jobDoc.data();

  const onClickJobTitle = () => {
    const jobId = jobDoc.id;
    const jobDetailsUrl = `${reactAppUrl}${replaceParamsInRoute(reactAppJobDetailsRoute, { jobId })}`;
    window.open(jobDetailsUrl);
  };

  return (
    <div className="job-info grid h-full grid-cols-6">
      <div className="col-span-4">
        <JobStatusBadge jobDoc={jobDoc} />
        <div
          className="job-title clickable line-clamp-2 h-12 hover:underline"
          title="Open Job Details"
          onClick={onClickJobTitle}
        >
          {jobData?.name}
        </div>
      </div>
      <div className="job-start-time col-span-2 line-clamp-1 flex items-start justify-end text-right">
        {getJobStartDatetime(jobDoc).getTimeStr()}
      </div>
      <div className="col-span-4 mt-2 ">
        <div className="job-client-name font-bold" title="Job Client">
          {jobData?.clientName}
        </div>
        <JobBrokerCompanyName jobDoc={jobDoc} />
      </div>
      <JobOperatorsCount classNames="col-span-2" jobDoc={jobDoc} />
    </div>
  );
}
