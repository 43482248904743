// Component to show a Dispatch Schedule Form
import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import ClientSelector from '@/components/DispatchSchedule/DispatchScheduleForm/ClientSelector';
import FormErrorAlert from '@/components/DispatchSchedule/DispatchScheduleForm/FormErrorAlert';
import RadiusFormControl from '@/components/DispatchSchedule/DispatchScheduleForm/RadiusFormControl';
import ScheduleNameInput from '@/components/DispatchSchedule/DispatchScheduleForm/ScheduleNameInput';
import SetAsDefaultScheduleCheckbox from '@/components/DispatchSchedule/DispatchScheduleForm/SetAsDefaultScheduleCheckbox';
import SiteSelector from '@/components/DispatchSchedule/DispatchScheduleForm/SiteSelector';
import { CloseDispatchScheduleFormModalFn } from '@/components/DispatchSchedule/DispatchScheduleFormModal';

import { useAuthContext } from '@/lib/context/AuthContext';

import {
  saveDispatchSchedule,
  updateUserDefaultDispatchSchedule,
} from '@/lib/firebase/db/helpers';
import { DispatchScheduleDoc } from '@/lib/firebase/db/metaTypes';

import { isClient } from '@/lib/helpers/userRoles';

export type DispatchScheduleFormData = {
  // Enabled parameters flags
  isClientEnabled: boolean;
  isSiteEnabled: boolean;
  isRadiusEnabled: boolean;
  // Dispatch Schedule data to save in db
  scheduleName: string;
  clientsIds: string[] | null; // Only Brokers should be able to set clients
  brokerIds: string[] | null; // Only Clients should be able to set brokers
  radiusZipCode: string | null;
  radiusDistanceMiles: number;
  isUserDefaultSchedule: boolean;
  sitesIds: string[] | null;
};

export const MIN_RADIUS_DISTANCE_MILES = 1;

export default function DispatchScheduleForm({
  onClose,
  dispatchScheduleDoc,
}: {
  onClose: CloseDispatchScheduleFormModalFn;
  dispatchScheduleDoc: DispatchScheduleDoc | null;
}) {
  const { userDoc } = useAuthContext();
  const isAlreadyUserDefaultSchedule =
    dispatchScheduleDoc?.id ===
    userDoc?.get('preferences.dispatchSchedule.defaultDispatchScheduleId');
  const methods = useForm<DispatchScheduleFormData>({
    mode: 'onChange',
    defaultValues: !dispatchScheduleDoc
      ? {
          radiusDistanceMiles: MIN_RADIUS_DISTANCE_MILES,
        }
      : {
          isClientEnabled: !!dispatchScheduleDoc.get('clientsIds')?.length,
          isSiteEnabled: !!dispatchScheduleDoc.get('sitesIds')?.length,
          isRadiusEnabled: !!dispatchScheduleDoc.get('radiusZipCode'),
          scheduleName: dispatchScheduleDoc.get('name'),
          clientsIds: dispatchScheduleDoc.get('clientsIds'),
          radiusZipCode: dispatchScheduleDoc.get('radiusZipCode'),
          radiusDistanceMiles: dispatchScheduleDoc.get('radiusDistanceMiles'),
          sitesIds: dispatchScheduleDoc.get('sitesIds'),
          isUserDefaultSchedule: isAlreadyUserDefaultSchedule,
        },
  });
  const [isLoading, setIsLoading] = useState(false);
  const { formState, watch, setValue } = methods;

  // Make Site(s) and Radius parameters mutually exclusive
  const isSiteEnabled = watch('isSiteEnabled');
  const isRadiusEnabled = watch('isRadiusEnabled');
  useEffect(() => {
    if (isSiteEnabled && isRadiusEnabled) {
      setValue('isRadiusEnabled', false);
    }
  }, [isSiteEnabled]);
  useEffect(() => {
    if (isSiteEnabled && isRadiusEnabled) {
      setValue('isSiteEnabled', false);
    }
  }, [isRadiusEnabled]);

  useEffect(() => {
    if ((formState.isSubmitting && !isLoading) || isLoading) {
      setIsLoading(formState.isSubmitting);
    }
  }, [formState]);

  const onCancel = () => {
    if (isLoading) return;
    onClose();
  };

  const onSubmit = async (data: DispatchScheduleFormData) => {
    if (isLoading) return;
    const {
      isSiteEnabled,
      isClientEnabled,
      isRadiusEnabled,
      scheduleName,
      isUserDefaultSchedule,
      ...dataToSave
    } = data;
    return saveDispatchSchedule(dispatchScheduleDoc?.id || null, {
      ...dataToSave,
      name: scheduleName,
    }).then((newDispatchSchedule) => {
      if (userDoc && newDispatchSchedule) {
        // Check if should update User default schedule preference
        if (isUserDefaultSchedule && !isAlreadyUserDefaultSchedule) {
          updateUserDefaultDispatchSchedule(userDoc, newDispatchSchedule.id);
        } else if (isAlreadyUserDefaultSchedule && !isUserDefaultSchedule) {
          updateUserDefaultDispatchSchedule(userDoc, '');
        }
      }
      onClose(newDispatchSchedule);
    });
  };
  const handleSubmit = methods.handleSubmit(onSubmit);

  if (!userDoc) {
    return null;
  }

  return (
    <>
      <FormProvider {...methods}>
        <div className="dispatch-schedule-form grid md:grid-cols-2">
          <div>
            <h1 className="mb-5 text-xl font-bold">
              {!dispatchScheduleDoc
                ? 'Create New Dispatch Schedule'
                : 'Edit Dispatch Schedule'}
            </h1>
          </div>
          <div>
            <FormErrorAlert />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <ScheduleNameInput dispatchScheduleDoc={dispatchScheduleDoc} />
            <SetAsDefaultScheduleCheckbox />
          </div>
          <div className="mb-5">
            <div className="text-lg font-bold">Parameters:</div>
            {!isClient(userDoc) && <ClientSelector />}
            <SiteSelector />
            <RadiusFormControl />
          </div>
        </form>
        <div className="modal-action">
          <button className="btn grow" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary grow"
            disabled={formState.isSubmitting}
            onClick={handleSubmit}
          >
            Submit
            {isLoading && (
              <span className="loading loading-spinner loading-xs"></span>
            )}
          </button>
        </div>
      </FormProvider>
    </>
  );
}
