// Component to manage  sort field and order in Scheduler Dashboard
import React from 'react';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  CheckIcon,
} from '@heroicons/react/16/solid';
import clsx from 'clsx';

import { startCase } from 'lodash';

import SchedulerOptionButton from '@/components/Scheduler/SchedulerDays/SchedulerOptions/SchedulerOptionButton';

import {
  SortField,
  SortOrder,
  sortFields,
  sortOrders,
  useSchedulerContext,
} from '@/lib/context/SchedulerContext';

type FieldAndOrderOption = { field: SortField; order: SortOrder };

export default function SchedulerSort() {
  const {
    schedulerSortField,
    schedulerSortOrder,
    setSchedulerSortField,
    setSchedulerSortOrder,
  } = useSchedulerContext();

  const onSelectOption = (option: FieldAndOrderOption) => {
    setSchedulerSortField(option.field);
    setSchedulerSortOrder(option.order);
  };

  const sortOptions: FieldAndOrderOption[] = sortFields
    .map((field) => {
      const fieldsByOrder = sortOrders.map((order) => ({ field, order }));
      return fieldsByOrder;
    })
    .flat();

  const sortLabelMap: Record<
    SortField | SortOrder,
    string | React.JSX.Element
  > = {
    asc: <ArrowUpIcon className="h-3 w-3" />,
    desc: <ArrowDownIcon className="h-3 w-3" />,
    startDatetime: 'Start Time',
    clientName: 'Client Name',
    overallStatus: 'Status',
  };

  return (
    <SchedulerOptionButton
      button={<ArrowsUpDownIcon className="h-6 w-6" />}
      tooltipText="Change Order"
      dropdownContent={
        <>
          {sortOptions.map((option) => {
            const isActive =
              option.field === schedulerSortField &&
              option.order === schedulerSortOrder;
            const optionLabel = startCase(`${sortLabelMap[option.field]}`);
            return (
              <li key={optionLabel + option.order}>
                <a
                  className={clsx(
                    'grid w-48 grid-cols-8',
                    isActive && 'bg-base-150'
                  )}
                  onClick={() => onSelectOption(option)}
                >
                  <div className="col-span-5">{optionLabel}</div>
                  <div className="col-span-2">{sortLabelMap[option.order]}</div>
                  <div>{isActive && <CheckIcon className="h-3 w-3" />}</div>
                </a>
              </li>
            );
          })}
        </>
      }
    />
  );
}
