import { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { every, trim } from 'lodash';

import { DispatchScheduleFormData } from '@/components/DispatchSchedule/DispatchScheduleForm';

import { useSchedulerContext } from '@/lib/context/SchedulerContext';

import { DispatchScheduleDoc } from '@/lib/firebase/db/metaTypes';

export default function ScheduleNameInput({
  dispatchScheduleDoc,
}: {
  dispatchScheduleDoc?: DispatchScheduleDoc | null;
}) {
  const { dispatchSchedulesOptions } = useSchedulerContext();
  const { register, formState } = useFormContext<DispatchScheduleFormData>();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(formState.isDirty && !!formState.errors.scheduleName);
  }, [formState]);

  const validateScheduleName = (scheduleName: string) => {
    // Check that schedule name has not changed or is unique among other options
    return (
      scheduleName.toLocaleLowerCase() ===
        dispatchScheduleDoc?.get('name')?.toLocaleLowerCase() ||
      every(dispatchSchedulesOptions, (dispatchScheduleOption) => {
        return (
          dispatchScheduleOption.name.toLocaleLowerCase() !==
          scheduleName.toLocaleLowerCase()
        );
      }) ||
      `Schedule named "${scheduleName}" already exist`
    );
  };

  return (
    <>
      <div className="text-lg font-bold">
        Schedule Name<span className="text-red-600">*</span>
      </div>
      <input
        {...register('scheduleName', {
          required: true,
          setValueAs: trim,
          validate: validateScheduleName,
          maxLength: 50,
        })}
        type="text"
        placeholder="How would you like to identify this schedule?"
        className={clsx(
          'input input-bordered w-full',
          hasError && 'input-error'
        )}
      />
    </>
  );
}
