// Component to Select current dispatch schedule
import { useState } from 'react';

import {
  ChevronDownIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/16/solid';
import clsx from 'clsx';

import DispatchScheduleFormModal from '@/components/DispatchSchedule/DispatchScheduleFormModal';
import Confirm from '@/components/Modal/Confirm';

import { useAuthContext } from '@/lib/context/AuthContext';
import {
  DispatchScheduleOption,
  useSchedulerContext,
} from '@/lib/context/SchedulerContext';

import { firestore } from '@/lib/firebase';
import { DispatchScheduleDoc } from '@/lib/firebase/db/metaTypes';

export default function SchedulerSelector() {
  const {
    selectedDispatchSchedule,
    dispatchSchedulesOptions,
    isLoadingDispatchScheduleOptions,
    setSelectedDispatchSchedule,
  } = useSchedulerContext();
  const { userDoc } = useAuthContext();
  const [isDispatchScheduleFormModalOpen, setIsDispatchScheduleFormModalOpen] =
    useState(false);
  const [dispatchScheduleToEdit, setDispatchScheduleToEdit] =
    useState<DispatchScheduleDoc | null>(null);

  const [dispatchScheduleToDelete, setDispatchScheduleToDelete] =
    useState<DispatchScheduleDoc | null>(null);
  const [
    isRemoveDispatchScheduleConfirmModalOpen,
    setIsRemoveDispatchScheduleConfirmModalOpen,
  ] = useState(false);

  const onCloseModal = (newDispatchSchedule: DispatchScheduleDoc | void) => {
    if (!!newDispatchSchedule) {
      setSelectedDispatchSchedule({
        dispatchScheduleDoc: newDispatchSchedule,
        dispatchScheduleId: newDispatchSchedule.id,
        name: newDispatchSchedule.data()?.name || '',
      });
    }
    setIsDispatchScheduleFormModalOpen(false);
  };

  const onClickCreateNewDispatchSchedule = () => {
    setIsDispatchScheduleFormModalOpen(true);
    setDispatchScheduleToEdit(null);
  };

  const onClickEditDispatchSchedule = (
    dispatchScheduleDoc: DispatchScheduleDoc | null
  ) => {
    setDispatchScheduleToEdit(dispatchScheduleDoc);
    setIsDispatchScheduleFormModalOpen(true);
  };

  const onClickRemoveDispatchSchedule = (
    dispatchScheduleDoc: DispatchScheduleDoc | null
  ) => {
    setDispatchScheduleToDelete(dispatchScheduleDoc);
    setIsRemoveDispatchScheduleConfirmModalOpen(true);
  };

  const onConfirmRemoveDispatchSchedule = () => {
    setIsRemoveDispatchScheduleConfirmModalOpen(false);
    if (
      dispatchScheduleToDelete?.id ===
      selectedDispatchSchedule.dispatchScheduleId
    ) {
      setSelectedDispatchSchedule(dispatchSchedulesOptions[0]);
    }
    if (!!dispatchScheduleToDelete && !!userDoc) {
      return firestore
        .delete(dispatchScheduleToDelete.ref, userDoc)
        .then(() => {
          setDispatchScheduleToDelete(null);
        });
    }
  };

  const onCancelRemoveDispatchSchedule = () => {
    setIsRemoveDispatchScheduleConfirmModalOpen(false);
    setDispatchScheduleToDelete(null);
  };

  return (
    <>
      <div className="scheduler-selector flex flex-1 justify-start">
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost px-0 text-lg md:px-4"
          >
            {isLoadingDispatchScheduleOptions ? (
              <div className="skeleton h-5 w-16 bg-base-200"></div>
            ) : (
              selectedDispatchSchedule.name
            )}
            <ChevronDownIcon className="size-8" />
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content z-[1] w-80 overflow-x-hidden rounded-box bg-base-100 p-2 shadow"
          >
            {dispatchSchedulesOptions.map((dispatchScheduleOption) => (
              <DispatchScheduleOptionItem
                key={dispatchScheduleOption.dispatchScheduleId || 'default'}
                dispatchScheduleOption={dispatchScheduleOption}
                onSelectDispatchSchedule={() =>
                  setSelectedDispatchSchedule(dispatchScheduleOption)
                }
                onEditDispatchSchedule={() =>
                  onClickEditDispatchSchedule(
                    dispatchScheduleOption.dispatchScheduleDoc
                  )
                }
                onRemoveDispatchSchedule={() =>
                  onClickRemoveDispatchSchedule(
                    dispatchScheduleOption.dispatchScheduleDoc
                  )
                }
              />
            ))}
            <li>
              <a
                className="flex justify-between italic text-primary underline"
                onClick={() => onClickCreateNewDispatchSchedule()}
              >
                <span>Create New</span>
                <PlusCircleIcon className="size-4" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <DispatchScheduleFormModal
        isOpen={isDispatchScheduleFormModalOpen}
        onClose={onCloseModal}
        dispatchScheduleDoc={dispatchScheduleToEdit}
      />
      <Confirm
        content={
          <>
            {dispatchScheduleToDelete && (
              <h3>{`Removing "${dispatchScheduleToDelete.get('name')}" Schedule`}</h3>
            )}
            <div>{'Are you sure to remove this Schedule?'}</div>
          </>
        }
        onConfirm={onConfirmRemoveDispatchSchedule}
        onCancel={onCancelRemoveDispatchSchedule}
        isOpen={isRemoveDispatchScheduleConfirmModalOpen}
      />
    </>
  );
}

function DispatchScheduleOptionItem({
  dispatchScheduleOption,
  onSelectDispatchSchedule,
  onEditDispatchSchedule,
  onRemoveDispatchSchedule,
}: {
  dispatchScheduleOption: DispatchScheduleOption;
  onSelectDispatchSchedule: () => void;
  onEditDispatchSchedule: () => void;
  onRemoveDispatchSchedule: () => void;
}) {
  const editOrRemoveOptionClassNames =
    'tooltip flex basis-1/12 px-2 opacity-0 group-hover:opacity-100';
  return (
    <li className="group flex w-full flex-row flex-nowrap">
      <div
        onClick={onSelectDispatchSchedule}
        className={clsx(
          'inline-block basis-10/12 truncate',
          !dispatchScheduleOption.dispatchScheduleId && 'flex-1 font-bold'
        )}
      >
        {dispatchScheduleOption.name}
      </div>
      {!!dispatchScheduleOption.dispatchScheduleId && (
        <>
          <div
            className={clsx(editOrRemoveOptionClassNames)}
            onClick={onEditDispatchSchedule}
            data-tip="Edit Schedule"
          >
            <PencilSquareIcon className="size-4" />
          </div>
          <div
            className={clsx(editOrRemoveOptionClassNames, 'tooltip-left')}
            onClick={onRemoveDispatchSchedule}
            data-tip="Delete Schedule"
          >
            <TrashIcon className="size-4" />
          </div>
        </>
      )}
    </li>
  );
}
