// Render a generic button to be used as Scheduler option
import React from 'react';

import clsx from 'clsx';

export default function SchedulerOptionButton({
  button,
  dropdownContent,
  noMenu,
  showButtonBadge,
  tooltipText,
  tooltipPosition,
}: {
  button: React.JSX.Element;
  dropdownContent: React.JSX.Element;
  noMenu?: boolean;
  showButtonBadge?: boolean;
  tooltipText?: string;
  tooltipPosition?: string;
}) {
  return (
    <div className="scheduler-option dropdown dropdown-end">
      <div className="flex h-full items-center">
        <div
          tabIndex={0}
          role="button"
          className="btn btn-ghost px-1 hover:bg-base-100"
        >
          {/* https://tailwind-starter-kit.vercel.app/docs/badges#:~:text=%3C/button%3E-,Icon%20with%20badge,-An%20icon%20with */}
          <div
            className={clsx(
              'relative inline-block',
              !!tooltipText && 'tooltip',
              !tooltipPosition ? 'tooltip-top' : tooltipPosition
            )}
            data-tip={tooltipText}
          >
            {button}

            <span
              className={clsx(
                'absolute right-0 top-0 inline-block -translate-y-1/2 translate-x-1/2 transform rounded-full bg-info transition-all duration-300',
                !showButtonBadge ? 'h-0 w-0' : 'h-2 w-2'
              )}
            ></span>
          </div>
        </div>
      </div>
      <ul
        tabIndex={0}
        className={clsx(
          !noMenu && 'menu',
          'dropdown-content z-[2] w-auto rounded-box bg-base-100 p-2 shadow'
        )}
      >
        {dropdownContent}
      </ul>
    </div>
  );
}
