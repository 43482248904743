// Render input to search and select sites for a dispatch schedule
import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { compact, map } from 'lodash';

import { DispatchScheduleFormData } from '@/components/DispatchSchedule/DispatchScheduleForm';
import SearchInput, { SelectedItemsList } from '@/components/Form/SearchInput';

import { firestore } from '@/lib/firebase';
import { Sites } from '@/lib/firebase/db/collections';
import { SiteDoc } from '@/lib/firebase/db/metaTypes';
import { sitesSearchByNameQuery } from '@/lib/firebase/db/queries';

const MAX_SITES_SELECTED = 30;

export default function SiteSelector() {
  const { formState, register, watch, setValue } =
    useFormContext<DispatchScheduleFormData>();
  const isEnabled = watch('isSiteEnabled');

  const [sitesSelected, setSitesSelected] = useState<SiteDoc[]>([]);
  const [siteNameSearchText, setSiteNameSearchText] = useState('');
  const [sitesSuggestions, setSitesSuggestions] = useState<SiteDoc[]>([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [isLoadingSelected, setIsLoadingSelected] = useState(false);

  useEffect(() => {
    if (!isEnabled && !!sitesSelected?.length) {
      setValue('sitesIds', []);
      setSitesSelected([]);
    }
    setValue('sitesIds', sitesSelected?.map((siteDoc) => siteDoc.id) || []);
  }, [sitesSelected, isEnabled]);

  // Load already selected sites
  useEffect(() => {
    async function loadSites() {
      setIsLoadingSelected(true);
      const sitesIds = compact(formState.defaultValues?.sitesIds);
      const sites = await Promise.all(
        map(sitesIds, (siteId) => firestore.getDoc(Sites.doc(siteId)))
      );
      setSitesSelected(sites);
      setIsLoadingSelected(false);
    }
    loadSites();
  }, [formState.defaultValues?.sitesIds]);

  // Load suggestions on change search text
  useEffect(() => {
    async function loadSuggestions(nameSearchTerm: string) {
      setIsLoadingSuggestions(true);
      const sites = await firestore.getDocs(
        sitesSearchByNameQuery({
          nameSearchTerm,
        })
      );
      const sitesFiltered = sites.docs.filter(
        (siteDoc) =>
          !sitesSelected?.some((siteSelected) => siteSelected.id === siteDoc.id)
      );
      setIsLoadingSuggestions(false);
      setSitesSuggestions(sitesFiltered);
    }
    if (!siteNameSearchText) {
      setSitesSuggestions([]);
      return;
    }
    loadSuggestions(siteNameSearchText);
  }, [siteNameSearchText]);

  const onSelectSuggestion = (siteSuggestion: SiteDoc) => {
    setSitesSelected(sitesSelected?.concat(siteSuggestion));
    setSiteNameSearchText('');
  };

  const onRemoveSite = (siteSelected: SiteDoc) => {
    setSitesSelected(
      sitesSelected?.filter((siteDoc) => siteDoc.id !== siteSelected.id)
    );
  };

  return (
    <div className="form-control mb-1 flex-row">
      <label className="clickable label basis-4/12 justify-start">
        <input
          type="radio"
          className="radio-primary radio mr-2 border-base-300"
          checked={isEnabled}
          {...register('isSiteEnabled')}
        />
        <span className="label-text">Site(s)</span>
      </label>
      <div className={clsx('w-full basis-8/12', !isEnabled && 'invisible')}>
        <SelectedItemsList
          selectedItems={sitesSelected}
          getItemValue={(siteDoc) => siteDoc.get('name')}
          onRemoveItem={onRemoveSite}
          isLoadingItems={isLoadingSelected}
          initialItemsCount={formState.defaultValues?.sitesIds?.length}
        />
        <SearchInput
          searchText={siteNameSearchText}
          placeholder={'Search Site by Name'}
          onChangeSearchText={(ev) => setSiteNameSearchText(ev.target.value)}
          disabled={
            !!sitesSelected && sitesSelected.length >= MAX_SITES_SELECTED
          }
          isLoading={isLoadingSuggestions}
          suggestions={sitesSuggestions}
          onSelectSuggestion={onSelectSuggestion}
          getSuggestionValue={(siteSuggestion) => siteSuggestion.get('name')}
        />
      </div>
    </div>
  );
}
