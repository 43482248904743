// Display count of accepted and total job operators
import clsx from 'clsx';

import { useAuthContext } from '@/lib/context/AuthContext';

import { getCompanyTrucksInfo } from '@/lib/firebase/db/helpers';
import { JobDoc } from '@/lib/firebase/db/metaTypes';

export default function JobOperatorsCount({
  jobDoc,
  classNames,
}: {
  jobDoc: JobDoc;
  classNames: string;
}) {
  const { userCompanyId } = useAuthContext();
  const companyTruckForUserCompany = getCompanyTrucksInfo(
    jobDoc,
    userCompanyId
  );
  const requestedTrucks = companyTruckForUserCompany.trucksRequestedCount;
  const assignedTrucks = companyTruckForUserCompany.trucksAssignedCount;
  const acceptedTrucks = companyTruckForUserCompany.trucksAcceptedCount;
  const countStr = `${acceptedTrucks}/${assignedTrucks}/${requestedTrucks} Trucks`;
  return (
    <div
      className={clsx(
        'job-operators-count  flex items-end justify-end text-xs font-light',
        classNames
      )}
    >
      <div
        className="tooltip tooltip-left before:max-w-44 before:text-left"
        data-tip={`
          Trucks Accepted:  ${acceptedTrucks}.
          Trucks Assigned:  ${assignedTrucks}.
          Trucks Requested: ${requestedTrucks}.
        `}
      >
        {countStr}
      </div>
    </div>
  );
}
