'use client';

import SchedulerDays from '@/components/Scheduler/SchedulerDays';
import SchedulerHeader from '@/components/Scheduler/SchedulerHeader';
import SchedulerMain from '@/components/Scheduler/SchedulerMain';

export default function SchedulerDashboard() {
  return (
    <div className="scheduler-dashboard flex h-full max-h-[95vh] flex-col">
      <div className="grow-0 basis-1/12 md:max-h-[calc(100%/12)]">
        <SchedulerHeader />
      </div>
      <div className="grow-0 basis-1/12">
        <SchedulerDays />
      </div>
      <div className="grow-0 basis-10/12">
        <SchedulerMain />
      </div>
    </div>
  );
}
