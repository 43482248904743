import { ExclamationCircleIcon } from '@heroicons/react/16/solid';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { get, map, startCase } from 'lodash';

import { DispatchScheduleFormData } from '@/components/DispatchSchedule/DispatchScheduleForm';

export default function FormErrorAlert() {
  const {
    formState: { errors },
  } = useFormContext<DispatchScheduleFormData>();
  const [error] = map(errors);

  const errorName = get(error, 'ref.name') || '';

  const errorMessage =
    error?.message ||
    (error?.type === 'required'
      ? `${startCase(errorName)} is required`
      : error?.type === 'maxLength'
        ? `${startCase(errorName)} exceeds allowed length`
        : '');

  return (
    <div
      role="alert"
      className={clsx(
        'alert alert-error text-sm',
        !errorMessage && 'hidden md:invisible md:grid'
      )}
    >
      <ExclamationCircleIcon className="h-6 w-6" />
      <span>{errorMessage || 'error'}</span>
    </div>
  );
}
