// Render a date picker that only allows to chose a fixed week
// Source: https://github.com/veljkocukic/react-week-picker
import React, { useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';
import { CalendarIcon } from '@heroicons/react/24/outline';

import {
  WEEK_START_DAY_NUMBER,
  addMonths,
  daysLabels,
  getDaysInMonth,
  getEndOfWeek,
  getStartOfWeek,
  subMonths,
} from '@/lib/helpers/dates';

import './weekPickerStyle.css';

export type WeekObject = {
  firstDay: Date;
  lastDay: Date;
};

export default function WeekPicker({
  startDate,
  endDate,
  onChange,
}: {
  startDate?: Date;
  endDate?: Date;
  onChange: (week: WeekObject) => void;
}) {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(startDate || new Date());
  const [week, setWeek] = useState({
    firstDay: startDate || getStartOfWeek(),
    lastDay: endDate || getEndOfWeek(),
  });

  const isLeapYear = () => {
    let leapYear = new Date(new Date().getFullYear(), 1, 29);
    return leapYear.getDate() == 29;
  };

  const convertDate = (date: Date) => {
    let dt = new Date(date);

    return `${dt.getDate()} ${months[dt.getMonth()]}`;
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    let localDate;
    const target = e.target as HTMLDivElement;
    if (target.id.includes('prev')) {
      localDate = new Date(date.setDate(1));
      setDate(new Date(date.setDate(1)));
    } else if (target.id.includes('next')) {
      localDate = new Date(date.setDate(getDaysInMonth(date)));
      setDate(new Date(date.setDate(getDaysInMonth(date))));
    } else {
      localDate = new Date(date.setDate(+target.id));
      setDate(new Date(date.setDate(+target.id)));
    }
    const firstDay = getStartOfWeek(localDate);
    const lastDay = getEndOfWeek(localDate);
    const selectedWeek = { firstDay, lastDay };
    setWeek(selectedWeek);
    if (!!onChange) {
      onChange(selectedWeek);
    }
  };

  const months = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'Jun',
    'July',
    'Aug.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];

  const days = {
    1: 31,
    2: isLeapYear() ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  type MonthNumber = keyof typeof days;

  const renderDays = () => {
    let month = (date.getMonth() + 1) as MonthNumber;
    let ar = [];
    for (let i = 1; i <= days[month]; i++) {
      let currentDate = new Date(date).setDate(i);

      let cName = 'single-number ';
      if (
        new Date(week.firstDay).getTime() <= new Date(currentDate).getTime() &&
        new Date(currentDate).getTime() <= new Date(week.lastDay).getTime()
      ) {
        cName = cName + 'selected-week';
      }

      ar.push(
        <div
          key={currentDate}
          id={`${i}`}
          className={cName}
          onClick={handleClick}
        >
          {i}
        </div>
      );
    }

    const displayDate = new Date(date).setDate(1);
    let dayInTheWeek = new Date(displayDate).getDay();
    if (dayInTheWeek < WEEK_START_DAY_NUMBER) {
      dayInTheWeek = WEEK_START_DAY_NUMBER + 6;
    }
    let prevMonth = [];
    let prevMonthNumber = new Date(date).getMonth() as MonthNumber | 0;
    if (prevMonthNumber === 0) {
      prevMonthNumber = 12;
    }
    let previousMonthFirstDay = new Date(
      `${date.getUTCFullYear()}-${prevMonthNumber}-01 12:00`
    );
    for (let i = dayInTheWeek; i > WEEK_START_DAY_NUMBER; i--) {
      const startOfWeekPreviousMonth =
        days[prevMonthNumber] - i + (WEEK_START_DAY_NUMBER + 1);
      let currentDate = new Date(previousMonthFirstDay).setDate(
        startOfWeekPreviousMonth
      );
      let cName = 'single-number other-month';
      let currentTime = new Date(currentDate).getTime();
      let firstTime = new Date(week.firstDay).getTime();
      let endTime = new Date(week.lastDay).getTime();
      if (currentTime >= firstTime && currentTime <= endTime) {
        cName = 'single-number selected-week';
      }

      prevMonth.push(
        <div
          onClick={handleClick}
          key={'prev-' + i}
          id={'prev-' + i}
          className={cName}
        >
          {startOfWeekPreviousMonth}
        </div>
      );
    }

    let nextMonth = [];
    let fullDays = 35;
    if ([...prevMonth, ...ar].length > 35) {
      fullDays = 42;
    }

    for (let i = 1; i <= fullDays - [...prevMonth, ...ar].length; i++) {
      let cName = 'single-number other-month';
      const lastDay = week.lastDay.getTime();
      const lastDayOfMonth = new Date(
        new Date(date).setDate(getDaysInMonth(date))
      );

      if (
        lastDayOfMonth.getTime() <= lastDay &&
        week.firstDay.getMonth() == lastDayOfMonth.getMonth()
      ) {
        cName = 'single-number selected-week';
      }

      nextMonth.push(
        <div
          onClick={handleClick}
          key={'next-' + i}
          id={'next-' + i}
          className={cName}
        >
          {i}
        </div>
      );
    }
    return [...prevMonth, ...ar, ...nextMonth];
  };

  const onChangeMonth = (goForward = false) => {
    let localDate = new Date(date);
    if (goForward) {
      localDate = addMonths(localDate, 1);
    } else {
      localDate = subMonths(localDate, 1);
    }
    setDate(new Date(localDate));
  };

  return (
    <div
      className="week-picker-display"
      onBlur={() => setOpen(false)}
      onClick={() => setOpen(true)}
      tabIndex={0}
    >
      <p>
        {week.firstDay.getDate()} - {convertDate(week.lastDay)}
      </p>
      <CalendarIcon className="ml-4 size-6" />
      {open && (
        <div className="week-picker-options">
          <div className="title-week">
            <div onClick={() => onChangeMonth()} className="arrow-container">
              <ChevronLeftIcon className="size-6" />
            </div>
            {`${months[date.getMonth()]} ${date.getFullYear()}.`}
            <div
              onClick={() => onChangeMonth(true)}
              className="arrow-container"
            >
              <ChevronRightIcon className="size-6" />
            </div>
          </div>
          <div className="numbers-container border-t border-t-base-150">
            {daysLabels.map((dayLabel) => (
              <div key={dayLabel} className="single-number day">
                {dayLabel}
              </div>
            ))}
          </div>
          <div className="numbers-container border-t border-t-base-150">
            {renderDays()}
          </div>
        </div>
      )}
    </div>
  );
}
