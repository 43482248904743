import { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { compact, filter, map, reject, xorBy } from 'lodash';

import { DispatchScheduleFormData } from '@/components/DispatchSchedule/DispatchScheduleForm';
import SearchInput, { SelectedItemsList } from '@/components/Form/SearchInput';

import { useAuthContext } from '@/lib/context/AuthContext';

import { firestore } from '@/lib/firebase';
import { Companies } from '@/lib/firebase/db/collections';
import { getClientOptionsForBrokerCompany } from '@/lib/firebase/db/helpers';

const MAX_CLIENTS_SELECTED = 30;
type CompanyOption = { id: string; name: string; clientId: string };

export default function ClientSelector() {
  const { userCompanyId } = useAuthContext();
  const { register, setValue, clearErrors, watch, formState } =
    useFormContext<DispatchScheduleFormData>();
  const isEnabled = watch('isClientEnabled');

  const [clientSearchText, setClientSearchText] = useState('');
  const [clientOptions, setClientOptions] = useState<CompanyOption[]>([]);
  const [selectedClients, setSelectedClients] = useState<CompanyOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clientsSuggestions, setClientsSuggestions] = useState<CompanyOption[]>(
    []
  );

  useEffect(() => {
    if (!isEnabled && !!selectedClients.length) {
      setSelectedClients([]);
    } else {
      setValue(
        'clientsIds',
        selectedClients?.map((clientOption) => clientOption.clientId) || []
      );
    }
  }, [selectedClients, isEnabled]);

  useEffect(() => {
    async function loadOptions() {
      const options = await getClientOptionsForBrokerCompany(userCompanyId);
      setClientOptions(options);
    }
    loadOptions();
  }, [userCompanyId]);

  // Load already selected clients
  useEffect(() => {
    async function loadClients() {
      setIsLoading(true);
      const clientsIds = compact(formState.defaultValues?.clientsIds);
      const clients = await Promise.all(
        map(clientsIds, (clientId) => firestore.getDoc(Companies.doc(clientId)))
      );
      setSelectedClients(
        clients.map((clientDoc) => ({
          id: clientDoc.id,
          name: clientDoc.get('businessName') || '',
          clientId: clientDoc.id,
        }))
      );
      setIsLoading(false);
    }
    loadClients();
  }, [formState.defaultValues?.clientsIds]);

  // Load suggestions on change search text
  useEffect(() => {
    const availableOptions = xorBy(clientOptions, selectedClients, 'clientId');
    const clients = !!clientSearchText.length
      ? filter(availableOptions, (clientOption) =>
          clientOption.name
            .toLowerCase()
            .startsWith(clientSearchText.toLowerCase())
        )
      : clientOptions;
    setClientsSuggestions(clients);
  }, [clientSearchText]);

  const onSelectSuggestion = (clientSuggestion: CompanyOption) => {
    setSelectedClients(selectedClients?.concat(clientSuggestion));
    setClientSearchText('');
  };

  const onRemoveClient = (clientSelected: CompanyOption) => {
    setSelectedClients(
      selectedClients?.filter((clientDoc) => clientDoc.id !== clientSelected.id)
    );
  };

  return (
    <div className="form-control mb-1 flex-row">
      <label className="clickable label basis-4/12 justify-start">
        <input
          type="checkbox"
          className="checkbox-primary checkbox mr-2 border-base-300"
          {...register('isClientEnabled')}
        />
        <span className="label-text">{'Client(s)'}</span>
      </label>
      <div className={clsx('w-full basis-8/12', !isEnabled && 'invisible')}>
        <SelectedItemsList
          selectedItems={selectedClients}
          getItemValue={(clientCompanyOption) => clientCompanyOption.name}
          onRemoveItem={onRemoveClient}
          isLoadingItems={isLoading}
          initialItemsCount={formState.defaultValues?.clientsIds?.length}
        />
        <SearchInput
          searchText={clientSearchText}
          placeholder={'Search Clients by Company Name'}
          onChangeSearchText={(ev) => setClientSearchText(ev.target.value)}
          disabled={
            !!selectedClients && selectedClients.length >= MAX_CLIENTS_SELECTED
          }
          suggestions={clientsSuggestions}
          onSelectSuggestion={onSelectSuggestion}
          getSuggestionValue={(clientCompanyOption) => clientCompanyOption.name}
        />
      </div>
    </div>
  );
}
