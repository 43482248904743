// Component to manage active filters in Scheduler Dashboard
import { useRef } from 'react';

import { Bars3BottomRightIcon, TrashIcon } from '@heroicons/react/16/solid';
import clsx from 'clsx';

import { identity, map, pickBy, size, startCase } from 'lodash';

import SchedulerOptionButton from '@/components/Scheduler/SchedulerDays/SchedulerOptions/SchedulerOptionButton';

import {
  FilterField,
  schedulerFilterColumns,
  useSchedulerContext,
} from '@/lib/context/SchedulerContext';

import { useShouldScroll } from '@/lib/helpers/hooks';

export default function SchedulerFilter() {
  const { schedulerFilterOptions, activeFiltersCount } = useSchedulerContext();
  const filterColumnsContainer = useRef<HTMLElement | null>(null);
  const shouldScrollFilterColumns = useShouldScroll(filterColumnsContainer);

  // Define classnames to manage width of grid container
  // based on number of columns to show
  type PossibleColCount = 1 | 2 | 3 | 4;
  const gridWidth: Record<PossibleColCount, string> = {
    1: 'w-[16rem]',
    2: 'w-[16rem] md:w-[32rem]',
    3: 'w-[16rem] md:w-[32rem] lg:w-[48rem]',
    4: 'w-[16rem] md:w-[32rem] lg:w-[48rem] xl:w-[64rem]',
  };
  const gridCols: Record<PossibleColCount, string> = {
    1: 'grid-cols-1',
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-2 lg:grid-cols-3',
    4: 'md:grid-cols-2 lg:grid-cols-3 xl:lg:grid-cols-4',
  };
  // Discard columns if there's no options to filter by
  const nonEmptyOptions = pickBy(
    schedulerFilterOptions,
    // Discard empty options or options with a single value
    (options) => options.length > 1
  );
  const nonEmptyOptionsCount = size(nonEmptyOptions);
  const colCount = Math.min(nonEmptyOptionsCount, 4);

  return (
    <SchedulerOptionButton
      noMenu
      button={<Bars3BottomRightIcon className="h-6 w-6" />}
      tooltipText="Filter Jobs"
      showButtonBadge={activeFiltersCount > 0}
      dropdownContent={
        !colCount ? (
          <div className="menu-title w-60 p-5 text-center">
            There are no available filters to apply
          </div>
        ) : (
          <div
            className={clsx(
              'scheduler-filter-dropdown',
              gridWidth[colCount as PossibleColCount]
            )}
          >
            <li
              ref={(ref) => {
                filterColumnsContainer.current = ref;
              }}
              className={clsx(
                'grid max-h-[66vh] w-full overflow-x-hidden md:max-h-[70vh]',
                gridCols[colCount as PossibleColCount],
                shouldScrollFilterColumns && 'overflow-y-scroll'
              )}
            >
              {map(nonEmptyOptions, (options, optionLabel) => (
                <SchedulerFilterColumn
                  key={optionLabel}
                  optionLabel={optionLabel}
                  options={options}
                  colWidthClass={gridWidth[1]}
                />
              ))}
            </li>
            <div className="flex justify-end">
              <ClearFiltersButton />
            </div>
          </div>
        )
      }
    />
  );
}

function SchedulerFilterColumn({
  optionLabel,
  options,
  colWidthClass,
}: {
  optionLabel: string;
  options: string[];
  colWidthClass: string;
}) {
  const filterField = optionLabel as FilterField;
  const filterColumnContainer = useRef<HTMLDivElement | null>(null);
  const shouldScrollFilterColumn = useShouldScroll(filterColumnContainer);

  return (
    <ul
      className={clsx('scheduler-filter-column menu', colWidthClass)}
      key={filterField}
    >
      <div className="menu-title">
        {schedulerFilterColumns[filterField].label || startCase(filterField)}
      </div>
      <div
        ref={filterColumnContainer}
        className={clsx(
          'max-w-full overflow-x-hidden md:max-h-64 md:flex-nowrap ',
          shouldScrollFilterColumn && 'md:overflow-y-scroll'
        )}
      >
        {options?.map((option) => (
          <SchedulerFilterOption
            key={option}
            filterField={filterField}
            filterValue={option}
          />
        ))}
      </div>
    </ul>
  );
}

function SchedulerFilterOption({
  filterField,
  filterValue,
}: {
  filterField: FilterField;
  filterValue: string;
}) {
  const { schedulerFilterValues, updateSchedulerFilterValue } =
    useSchedulerContext();
  const formatOption =
    schedulerFilterColumns[filterField].formatOption || identity;
  return (
    <li>
      <label className="clickable px-2">
        <input
          className="checkbox-accent checkbox checkbox-xs rounded border-base-300"
          type="checkbox"
          checked={!!schedulerFilterValues[filterField]?.includes(filterValue)}
          onChange={() => updateSchedulerFilterValue(filterField, filterValue)}
        />
        <span className="label-text text-xs">{formatOption(filterValue)}</span>
      </label>
    </li>
  );
}

function ClearFiltersButton() {
  const { activeFiltersCount, clearActiveFilters } = useSchedulerContext();
  return (
    <div
      className={clsx(
        'btn btn-ghost btn-xs text-primary-dark transition-all duration-300',
        activeFiltersCount === 0 && 'btn-disabled opacity-0'
      )}
      onClick={clearActiveFilters}
    >
      <TrashIcon className="size-4" />
      Clear Filters
    </div>
  );
}
