// Render Job Broker Company Name
import { useEffect, useState } from 'react';

import { PencilSquareIcon } from '@heroicons/react/16/solid';
import { useToggle } from '@uidotdev/usehooks';

import { useAuthContext } from '@/lib/context/AuthContext';
import { useSchedulerContext } from '@/lib/context/SchedulerContext';

import { firestore } from '@/lib/firebase';
import { Companies, getCompanyBrokers } from '@/lib/firebase/db/collections';
import { getHasJobSomeInvoicedTask } from '@/lib/firebase/db/helpers';
import { CompanyBrokerDoc, JobDoc } from '@/lib/firebase/db/metaTypes';

import { canUserEditJobBrokerCompany } from '@/lib/helpers/userRoles';

export default function JobBrokerCompanyName({ jobDoc }: { jobDoc: JobDoc }) {
  const { userDoc } = useAuthContext();
  const { brokerCompanies, isLoadingBrokerCompanies } = useSchedulerContext();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const [isSaving, toggleIsSaving] = useToggle(false);
  const [hasJobSomeInvoicedTask, setHasJobSomeInvoicedTask] = useState(true);
  const brokerCompanyId = jobDoc.get('brokerCompanyRef')?.id;

  useEffect(() => {
    if (!userDoc || !canUserEditJobBrokerCompany(userDoc, jobDoc)) return;
    getHasJobSomeInvoicedTask(jobDoc).then(setHasJobSomeInvoicedTask);
  }, [jobDoc]);

  function onChangeBrokerCompany(brokerCompanyId: string) {
    if (!userDoc) return;

    toggleIsEditing();
    toggleIsSaving();
    firestore
      .save(
        jobDoc.ref,
        { brokerCompanyRef: Companies.doc(brokerCompanyId) },
        userDoc
      )
      .then(() => {
        toggleIsSaving();
      });
  }

  if (!brokerCompanyId || !userDoc) return null;

  const brokerCompanyDoc = brokerCompanies[brokerCompanyId];
  if (!brokerCompanyDoc && (isLoadingBrokerCompanies || isSaving)) {
    return <div className="skeleton h-6 w-full bg-secondary-dark"></div>;
  }

  if (!brokerCompanyDoc) return null;

  if (isEditing) {
    return (
      <JobBrokerCompanySelector
        jobDoc={jobDoc}
        onChange={onChangeBrokerCompany}
      />
    );
  }

  return (
    <div className="broker-company-name group flex h-6 items-center gap-1 text-sm">
      <div title="Job Broker">{brokerCompanyDoc.get('businessName')}</div>
      {canUserEditJobBrokerCompany(userDoc, jobDoc) &&
        !hasJobSomeInvoicedTask && (
          <EditBrokerCompanyButton onClick={() => toggleIsEditing()} />
        )}
    </div>
  );
}

function EditBrokerCompanyButton({ onClick }: { onClick: () => void }) {
  return (
    <div
      className="tooltip tooltip-right invisible group-hover:visible"
      data-tip="Edit Broker Company"
    >
      <PencilSquareIcon
        className="edit-broker-button clickable size-4"
        onClick={onClick}
      />
    </div>
  );
}

function JobBrokerCompanySelector({
  jobDoc,
  onChange,
}: {
  jobDoc: JobDoc;
  onChange: (id: string) => void;
}) {
  const { userCompanyId } = useAuthContext();
  const [brokerCompaniesOptions, setBrokerCompaniesOptions] = useState<
    CompanyBrokerDoc[]
  >([]);
  const [selectedBrokerCompanyId, setSelectedBrokerCompanyId] = useState(
    jobDoc.get('brokerCompanyRef')?.id
  );

  useEffect(() => {
    if (!userCompanyId) return;
    firestore
      .getDocs(getCompanyBrokers(userCompanyId))
      .then(({ docs: companyBrokerDocs }) => {
        setBrokerCompaniesOptions(companyBrokerDocs);
      });
  }, [userCompanyId]);

  useEffect(() => {
    if (
      !!selectedBrokerCompanyId &&
      selectedBrokerCompanyId !== jobDoc.get('brokerCompanyRef')?.id
    ) {
      onChange(selectedBrokerCompanyId);
    }
  }, [selectedBrokerCompanyId]);

  return (
    <select
      className="job-broker-company-selector select select-bordered select-xs w-full max-w-xs bg-accent"
      value={selectedBrokerCompanyId}
      onChange={(ev) => setSelectedBrokerCompanyId(ev.target.value)}
    >
      {brokerCompaniesOptions.map((brokerCompanyDoc) => (
        <option
          key={brokerCompanyDoc.id}
          value={brokerCompanyDoc.get('brokerCompany.id')}
        >
          {brokerCompanyDoc.get('brokerCompany.businessName')}
        </option>
      ))}
    </select>
  );
}
