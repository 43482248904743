// Component to display information of a single JobAssignment
import React from 'react';

import {
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { TruckIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useDrag } from 'react-dnd';

import { compact, size, startCase } from 'lodash';

import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';

import { JobAssignmentDoc, JobDoc } from '@/lib/firebase/db/metaTypes';

import {
  reactAppOperatorDetailsRoute,
  reactAppTaskDetailsRoute,
  reactAppUrl,
} from '@/lib/env';
import { replaceParamsInRoute } from '@/lib/helpers/functions';

export default function JobAssignmentInfo({
  jobDoc,
  jobAssignmentDoc,
}: {
  jobDoc: JobDoc;
  jobAssignmentDoc: JobAssignmentDoc;
}) {
  const { selectedJob, selectedGroup, onSelectOperatorToRemove } =
    useEditSchedulerContext();

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: `job-${jobDoc.id}-group-${jobAssignmentDoc.get('groupIndex')}`,
    item: { jobAssignmentDoc },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-30' : 'opacity-100',
    }),
  }));

  const receiverStatus =
    jobAssignmentDoc.get('receiver.status') || 'not notified';
  const taskApprovalStatus = jobAssignmentDoc.get('task.approvalStatus') || '';
  const borderColorByStatus: Record<string, string> = {
    ['not notified']: 'border-status-neutral',
    notified: 'border-status',
    accepted: 'border-status-good',
    declined: 'border-status-bad',
    approved: 'border-status-good',
    denied: 'border-status-bad',
  };
  const borderColor =
    borderColorByStatus[receiverStatus] ||
    borderColorByStatus[taskApprovalStatus] ||
    'border-status';
  const isAccepted = receiverStatus === 'accepted';
  const isDeclined = receiverStatus === 'declined';
  const isRunning = receiverStatus === 'checkedIn';
  const isCompleted = receiverStatus === 'checkedOut';
  const isTaskApproved = taskApprovalStatus === 'approved';
  const isTaskDenied = taskApprovalStatus === 'denied';

  const companyName = jobAssignmentDoc.get('receiver.company.name');
  const operatorName =
    jobAssignmentDoc.get('receiver.user.nickname') ||
    jobAssignmentDoc.get('receiver.user.email');
  const label = compact([companyName, operatorName]).join(' - ');

  const operatorId = jobAssignmentDoc.get('receiver.user.id');
  const onClickOperatorLabel = () => {
    if (!operatorId) return;
    const operatorDetailsUrl = `${reactAppUrl}${replaceParamsInRoute(reactAppOperatorDetailsRoute, { operatorId })}`;
    window.open(operatorDetailsUrl);
  };

  const taskId = jobAssignmentDoc.get('task.id');
  const onClickStatusIcon = () => {
    if (!taskId) return;
    const taskDetailsUrl = `${reactAppUrl}${replaceParamsInRoute(reactAppTaskDetailsRoute, { taskId })}`;
    window.open(taskDetailsUrl);
  };

  const isSelectedJob = selectedJob?.id === jobDoc.id;

  // Do not allow removing an operator if a group is selected to add an operator or
  // if the operator has already started a task.
  const canOperatorBeRemoved =
    isSelectedJob && !jobAssignmentDoc.get('task.id');

  const canOperatorBeMoved =
    canOperatorBeRemoved && size(jobDoc.get('groupsOfOperators')) > 1;

  return (
    <div className={clsx('flex items-center [&:not(:last-child)]:mb-0.5')}>
      {canOperatorBeRemoved && (
        <XMarkIcon
          onClick={() => onSelectOperatorToRemove(jobAssignmentDoc)}
          className="clickable inline size-4"
          title="Remove Operator"
        />
      )}
      <div
        // @ts-ignore
        ref={preview}
        className={clsx(
          'job-assignment-info flex justify-between rounded-xl border-2',
          'w-full select-none px-1 py-0.5 text-xs',
          opacity,
          borderColor
        )}
      >
        <div className="flex">
          <div
            className={clsx(
              !!operatorId && 'clickable hover:underline',
              'max-w-72 overflow-hidden text-ellipsis sm:max-w-96 md:max-w-full',
              isDeclined && 'font-bold text-status-bad'
            )}
            title="Open Operator Profile"
            onClick={onClickOperatorLabel}
          >
            {label}
          </div>
        </div>
        <div
          className={clsx(
            'tooltip tooltip-left flex items-center',
            !!taskId && 'clickable'
          )}
          onClick={onClickStatusIcon}
          data-tip={`${startCase(isTaskApproved || isTaskDenied ? taskApprovalStatus : receiverStatus)}`}
          title={!!taskId ? 'Open Task Report' : ''}
        >
          {!isCompleted ? (
            isAccepted ? (
              <ThumbUpIcon />
            ) : isDeclined ? (
              <ThumbDownIcon />
            ) : isRunning ? (
              <TruckIcon className="size-4 text-status" />
            ) : (
              <NotifiedIcon
                stroke={
                  receiverStatus === 'notified'
                    ? 'stroke-status'
                    : 'stroke-status-neutral'
                }
                fill={
                  receiverStatus === 'notified'
                    ? 'fill-status'
                    : 'fill-status-neutral'
                }
              />
            )
          ) : isTaskDenied ? (
            <ExclamationCircleIcon className="size-4 text-status-bad" />
          ) : isTaskApproved ? (
            <CheckMarkIcon fill={'fill-status-good'} />
          ) : (
            <PencilSquareIcon className="size-4 text-status" />
          )}
        </div>
      </div>
      {canOperatorBeMoved && (
        <HandleIcon
          // @ts-ignore
          ref={drag}
        />
      )}
    </div>
  );
}

const ICON_SIZE_DEFAULT = 15;

function NotifiedIcon({
  size = ICON_SIZE_DEFAULT,
  fill = 'fill-status',
  stroke = 'stroke-status',
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72819 7.33398V5.93996"
        className={clsx(stroke)}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72819 4.08041C5.47506 4.08041 5.26986 4.28846 5.26986 4.54509C5.26986 4.80172 5.47506 5.00977 5.72819 5.00977C5.98132 5.00977 6.18652 4.80172 6.18652 4.54509C6.18652 4.28846 5.98132 4.08041 5.72819 4.08041Z"
        className={clsx(fill)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72884 10.5865C8.26015 10.5865 10.3122 8.50607 10.3122 5.93973C10.3122 3.37339 8.26015 1.29297 5.72884 1.29297C3.19754 1.29297 1.14551 3.37339 1.14551 5.93973C1.14551 8.50607 3.19754 10.5865 5.72884 10.5865Z"
        className={clsx(stroke)}
      />
    </svg>
  );
}

function ThumbUpIcon({ size = ICON_SIZE_DEFAULT, fill = 'fill-status-good' }) {
  return (
    <svg
      width={size}
      height={size}
      fill="currentColor"
      className={clsx(fill)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        className={clsx(fill)}
        d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
      />
    </svg>
  );
}

function ThumbDownIcon({ size = ICON_SIZE_DEFAULT, fill = 'fill-status-bad' }) {
  return (
    <svg
      width={size}
      height={size}
      fill="currentColor"
      className={clsx(fill)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        className={clsx(fill)}
        d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z"
      />
    </svg>
  );
}

function CheckMarkIcon({
  size = ICON_SIZE_DEFAULT,
  fill = 'fill-status-light',
  stroke = 'stroke-base-300',
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82237 0.192591L8.82244 0.192533C8.90416 0.127709 8.99475 0.111059 9.07858 0.136212C9.16502 0.162149 9.25107 0.234903 9.31019 0.353577L9.80613 1.34987C9.85988 1.45791 9.88413 1.59066 9.87189 1.72373L9.99637 1.73517L9.87189 1.72373C9.85964 1.85695 9.81228 1.97547 9.74352 2.05979L9.73692 2.06788L9.73546 2.07045L9.73511 2.07088L9.73506 2.07094L9.7065 2.10591L9.70649 2.10591L9.70545 2.10721L9.59273 2.24908L9.59217 2.24979C8.9669 3.04859 8.36063 3.8727 7.77424 4.72092L7.7742 4.72098C6.67171 6.31782 5.35944 8.41191 4.475 10.4472C4.36549 10.6991 4.18462 10.8366 4.00868 10.8679C3.83661 10.8985 3.64324 10.8323 3.48978 10.6219L3.48972 10.6218L0.240894 6.17586C0.203655 6.12488 0.173392 6.06286 0.153096 5.99296C0.132811 5.92311 0.123225 5.8479 0.12527 5.77206C0.127315 5.69622 0.140922 5.62234 0.164763 5.55499C0.188619 5.4876 0.221831 5.42923 0.26112 5.38257L0.261138 5.38255L1.24303 4.21598C1.24304 4.21597 1.24305 4.21596 1.24305 4.21596C1.31092 4.13538 1.39129 4.09566 1.4678 4.09114C1.54385 4.08664 1.62461 4.11637 1.69577 4.18674L1.69583 4.18679L3.35357 5.82385L3.45419 5.92322L3.54045 5.81116C6.12376 2.45542 7.58555 1.17223 8.82237 0.192591Z"
        className={clsx(fill, stroke)}
        strokeWidth="0.25"
      />
    </svg>
  );
}

const HandleIcon = React.forwardRef<HTMLDivElement>(function HandleIcon(
  {},
  ref
) {
  return (
    <div ref={ref} className="relative size-4 cursor-grab">
      <EllipsisVerticalIcon className="absolute size-4" />
      <EllipsisVerticalIcon className="absolute left-1 size-4" />
    </div>
  );
});
