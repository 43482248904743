// Customs hooks to use within react components
import { useEffect, useState } from 'react';

/**
 * Custom React hook to determine if an element should scroll based on its content size.
 * This hook utilizes a ResizeObserver to monitor changes in the size of a referenced div element, and determines
 * whether the content of the element is larger than the element itself, necessitating scrolling. It's particularly
 * useful for dynamically adjusting UI elements or functionality based on the presence of overflow content.
 *
 * @param {React.MutableRefObject<HTMLElement | null>} ref - A ref object pointing to the DOM element to be observed.
 * @returns {boolean} A stateful value indicating whether the observed element should enable scrolling to accommodate
 * its content.
 */
export const useShouldScroll = (
  ref: React.MutableRefObject<HTMLElement | null>
) => {
  const [shouldScroll, setShouldScroll] = useState(false);
  useEffect(() => {
    if (ref.current) {
      const divElement = ref.current;
      const observer = new ResizeObserver((entries) => {
        const [entry] = entries;
        if (entry) {
          const target = entry.target as HTMLElement;
          setShouldScroll(target.offsetHeight < target.scrollHeight);
        }
      });
      observer.observe(divElement);
      return () => observer.disconnect();
    }
  }, [ref]);
  return shouldScroll;
};
