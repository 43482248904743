// Component to change the range dates (weekly) of current dispatch schedule
import WeekPicker, { WeekObject } from '@/components/WeekPicker/WeekPicker';

import { useSchedulerContext } from '@/lib/context/SchedulerContext';

export default function SchedulerRange() {
  const { schedulerStartDate, schedulerEndDate, setSchedulerRangeDates } =
    useSchedulerContext();

  const onChangeWeek = (week: WeekObject) => {
    setSchedulerRangeDates(week.firstDay, week.lastDay);
  };

  return (
    <div className="scheduler-range flex flex-1 justify-end md:py-5">
      <WeekPicker
        startDate={schedulerStartDate}
        endDate={schedulerEndDate}
        onChange={onChangeWeek}
      />
    </div>
  );
}
