// Render Checkbox element to change user default dispatch schedule preference
import { useFormContext } from 'react-hook-form';

import { DispatchScheduleFormData } from '@/components/DispatchSchedule/DispatchScheduleForm';

export default function SetAsDefaultScheduleCheckbox() {
  const { register } = useFormContext<DispatchScheduleFormData>();

  return (
    <div className="form-control">
      <label className="clickable label basis-2/12 justify-start">
        <input
          type="checkbox"
          className="checkbox-primary checkbox mr-2 border-base-300"
          {...register('isUserDefaultSchedule')}
        />
        <span className="label-text">Choose this Schedule by default</span>
      </label>
    </div>
  );
}
