// Component to change sort order or filters for displaying jobs in current schedule
import SchedulerFilter from '@/components/Scheduler/SchedulerDays/SchedulerOptions/SchedulerFilter';
import SchedulerInfo from '@/components/Scheduler/SchedulerDays/SchedulerOptions/SchedulerInfo';
import SchedulerSort from '@/components/Scheduler/SchedulerDays/SchedulerOptions/SchedulerSort';
import ShowOperatorsToggle from '@/components/Scheduler/SchedulerDays/SchedulerOptions/ShowOperatorsToggle';

export default function SchedulerOptions() {
  return (
    <div className="scheduler-options flex justify-end gap-4">
      <ShowOperatorsToggle />
      <SchedulerFilter />
      <SchedulerSort />
      <SchedulerInfo />
    </div>
  );
}
