// Render a Input component to perform search
import { XMarkIcon } from '@heroicons/react/16/solid';
import clsx from 'clsx';

import { times } from 'lodash';

type WithID = { id: string; isDummy?: boolean };

type SearchInputProps<T> = {
  searchText: string;
  onChangeSearchText: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  isLoading?: boolean;
  suggestions: T[];
  placeholder: string;
  onSelectSuggestion: (s: T) => void;
  getSuggestionValue: (s: T) => string | undefined;
};

export default function SearchInput<T extends WithID>({
  searchText,
  onChangeSearchText,
  disabled,
  isLoading,
  suggestions,
  placeholder,
  onSelectSuggestion,
  getSuggestionValue,
}: SearchInputProps<T>) {
  return (
    <div className="search-input dropdown w-full">
      <input
        type="text"
        placeholder={placeholder}
        className="input input-bordered w-full "
        value={searchText}
        onChange={onChangeSearchText}
        disabled={disabled}
      />
      <ul
        tabIndex={0}
        className={clsx(
          'menu dropdown-content z-[1] max-h-40 w-full flex-nowrap overflow-auto rounded-box bg-base-100 p-2 shadow',
          !searchText.length && '!invisible'
        )}
      >
        {!isLoading && !suggestions?.length ? (
          <li>{`No results for "${searchText}"`}</li>
        ) : (
          suggestions?.map((suggestion) => (
            <li key={suggestion.id}>
              <a onClick={() => onSelectSuggestion(suggestion)}>
                {getSuggestionValue(suggestion)}
              </a>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

export function SelectedItemsList<T extends WithID>({
  selectedItems,
  getItemValue,
  onRemoveItem,
  isLoadingItems = false,
  initialItemsCount = 0,
}: {
  selectedItems: T[];
  getItemValue: (i: T) => string | undefined;
  onRemoveItem?: (i: T) => void;
  isLoadingItems?: boolean;
  initialItemsCount?: number;
}) {
  const items = !isLoadingItems
    ? selectedItems
    : // Generate dummy elements to render as skeletons while real suggestions are loading
      times(
        initialItemsCount,
        (idx) => ({ id: idx.toString(), isDummy: true }) as T
      );
  return (
    <div className="flex flex-wrap">
      {items?.map((item) => (
        <div
          key={item.id}
          className="mb-1 mr-1 flex items-center rounded-lg border py-1 pl-2 pr-1 text-xs"
        >
          {item.isDummy ? (
            <div className="skeleton h-2 w-20 bg-base-200"></div>
          ) : (
            <div>{getItemValue(item)}</div>
          )}
          {!!onRemoveItem && (
            <XMarkIcon
              onClick={() => onRemoveItem(item)}
              className="clickable size-6 text-base-200"
            />
          )}
        </div>
      ))}
    </div>
  );
}
