// Scheduler Main content component
import { useRef } from 'react';

import clsx from 'clsx';

import JobsList from '@/components/Job/JobsList';
import OperatorSearchWidget from '@/components/Search/OperatorSearchWidget';

import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';

import { useShouldScroll } from '@/lib/helpers/hooks';

export default function SchedulerMain() {
  const { selectedGroup, selectedJob } = useEditSchedulerContext();
  const jobsListContainer = useRef<HTMLDivElement | null>(null);
  const shouldScrollJobsList = useShouldScroll(jobsListContainer);

  return (
    <div className="scheduler-main relative grid max-h-[79vh] min-h-full grid-cols-4 justify-center">
      {!!selectedJob && selectedGroup !== null && (
        <div className="col-span-1 hidden pl-1 pt-2 md:block lg:pl-5">
          <OperatorSearchWidget />
        </div>
      )}
      <div
        ref={jobsListContainer}
        className={clsx(
          'col-span-4 max-h-[inherit] overflow-x-hidden px-2 py-6',
          'transition-all', // Animate expansion and contraction of Jobs List
          shouldScrollJobsList && 'overflow-y-scroll',
          selectedGroup === null
            ? 'md:px-10' // Search widget closed
            : 'md:col-span-3' // Search widget open
        )}
      >
        <JobsList />
      </div>
    </div>
  );
}
