import { useEffect, useState } from 'react';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/16/solid';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { toNumber } from 'lodash';

import {
  DispatchScheduleFormData,
  MIN_RADIUS_DISTANCE_MILES,
} from '@/components/DispatchSchedule/DispatchScheduleForm';

import { getIsValidUSZipCode, getLatLngByZipCode } from '@/lib/helpers/maps';

export default function RadiusFormControl() {
  const { register, watch, clearErrors, resetField, formState } =
    useFormContext<DispatchScheduleFormData>();
  const isEnabled = watch('isRadiusEnabled');
  const [isLatLngAvailable, setIsLatLngAvailable] = useState(false);
  const [isLoadingLatLng, setIsLoadingLatLng] = useState(false);
  const currentZipCode = watch('radiusZipCode', '');

  useEffect(() => {
    if (!!formState.defaultValues?.radiusZipCode) {
      validateZipCode(formState.defaultValues?.radiusZipCode);
    }
  }, []);

  useEffect(() => {
    if (!isEnabled) {
      resetField('radiusZipCode', { defaultValue: '' });
      resetField('radiusDistanceMiles');
      clearErrors('radiusZipCode');
      setIsLatLngAvailable(false);
    }
  }, [isEnabled]);

  const validateZipCode = async (zipCode: string | null) => {
    // Do not validate when checkbox is disabled otherwise it will prevent submitting.
    if (!isEnabled) return true;
    const isValidRegEx = !!zipCode && getIsValidUSZipCode(zipCode);
    if (!isValidRegEx) {
      setIsLatLngAvailable(isValidRegEx);
      return isValidRegEx;
    }
    setIsLoadingLatLng(true);
    return getLatLngByZipCode(zipCode).then((latLng) => {
      const isAvailable = !!latLng?.lat && !!latLng?.lng;
      setIsLatLngAvailable(isAvailable);
      setIsLoadingLatLng(false);
      return isAvailable;
    });
  };

  const RadiusInputIcon = isLatLngAvailable
    ? CheckCircleIcon
    : ExclamationCircleIcon;

  return (
    <div className="form-control">
      <label className="clickable label basis-2/12 justify-start">
        <input
          type="radio"
          className="radio-primary radio mr-2 border-base-300"
          checked={isEnabled}
          {...register('isRadiusEnabled')}
        />
        <span className="label-text">Radius </span>
        <span
          className="tooltip tooltip-right"
          data-tip="Specify a perimeter around a ZIP code to include all jobs that have their initial site within"
        >
          <QuestionMarkCircleIcon className="tooltip ml-2 size-5 text-base-200" />
        </span>
      </label>
      <div className={clsx('form-control flex-row', !isEnabled && 'invisible')}>
        <label className="clickable label  basis-6/12 justify-start gap-1">
          <span className="label-text basis-4/12 text-center">ZIP code:</span>
          <label className="clickable label input input-bordered relative basis-8/12 pr-1">
            <input
              {...register('radiusZipCode', {
                validate: validateZipCode,
              })}
              type="text"
              className="w-full"
            />
            <div
              className={clsx(
                'w-6',
                !!currentZipCode &&
                  !isLoadingLatLng &&
                  !isLatLngAvailable &&
                  'tooltip'
              )}
              data-tip={`${currentZipCode} is not a valid ZIP Code`}
            >
              {!!currentZipCode && !isLoadingLatLng && (
                <RadiusInputIcon
                  className={clsx(
                    'size-6',
                    isLatLngAvailable ? 'text-success' : 'text-error'
                  )}
                />
              )}
            </div>
          </label>
        </label>
        <label className="clickable label basis-6/12 justify-start gap-2">
          <span className="label-text basis-6/12 text-center">Distance:</span>
          <label className="input input-bordered flex items-center gap-2 ">
            <input
              {...register('radiusDistanceMiles', {
                min: MIN_RADIUS_DISTANCE_MILES,
                setValueAs: toNumber,
              })}
              type="number"
              className="w-full"
              min={MIN_RADIUS_DISTANCE_MILES}
            />
            <span className="text-xs">{'mi'}</span>
          </label>
        </label>
      </div>
    </div>
  );
}
