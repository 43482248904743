// Component to switch between the current day of dispatch schedule
import clsx from 'clsx';

import { useSchedulerContext } from '@/lib/context/SchedulerContext';

import { addDays, daysLabels, getIsSameDay } from '@/lib/helpers/dates';

export default function SchedulerDaySwitch() {
  const { schedulerCurrentDate, schedulerStartDate, setSchedulerCurrentDate } =
    useSchedulerContext();
  return (
    <div className="scheduler-day-switch tabs mb-[-2px] flex justify-between lg:tabs-bordered lg:justify-start">
      {daysLabels.map((dayLabel, idx) => {
        const date = addDays(schedulerStartDate, idx);
        const isActive = getIsSameDay(schedulerCurrentDate, date);
        return (
          <div
            key={dayLabel}
            className={clsx(
              'tab h-full min-h-12 grow px-0 text-lg lg:px-10',
              isActive
                ? 'tab-active bg-base-150'
                : '!border-base-200 font-bold text-primary-dark'
            )}
            onClick={() => setSchedulerCurrentDate(date)}
          >
            <div className="tooltip tooltip-top" data-tip={date.toDateString()}>
              {dayLabel}
            </div>
          </div>
        );
      })}
    </div>
  );
}
