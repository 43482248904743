// Render an invisible overlay component that covers the entire container to block any actionable sibling
import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';

export default function BlockSchedulerOptionsOverlay() {
  const { selectedJob, operationsRecordList } = useEditSchedulerContext();
  return (
    (!!selectedJob || !!operationsRecordList.length) && (
      <div
        className="block-scheduler-options-overlay absolute z-[2] h-full w-full cursor-not-allowed"
        title={
          !!operationsRecordList.length
            ? 'Confirm or discard changes made over job assignments to unlock these options'
            : !!selectedJob
              ? 'Unselect current job to unlock these options'
              : ''
        }
      />
    )
  );
}
