// Render toggle component to switch value of showOperators flag in SchedulerContext
import { useSchedulerContext } from '@/lib/context/SchedulerContext';

export default function ShowOperatorsToggle() {
  const { showOperators, onToggleShowOperators } = useSchedulerContext();
  return (
    <div className="show-operators-toggle flex items-center">
      <span className="text-xs">{'Show Operators '}</span>
      <input
        type="checkbox"
        className="toggle toggle-sm ml-1"
        checked={showOperators}
        onChange={onToggleShowOperators}
      />
    </div>
  );
}
